<template>
    <transition name="modal">
        <div class="modal-mask" @click="closeModal" v-if="show">
            <div class="modal-wrapper">
                <div class="modal-container" :class="{'modal-fixed-footer': fixedFooter, 'modal-fullScreen': fullScreen}" @click.stop :style="{'width': `${width}`, 'padding': `${padding}`, 'max-width': `${MaxWidth}`}">
                    <div v-if="showCloseBtn" class="modal-closeBtn" @click="$emit('close')">
                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
                            <g fill="none" fill-rule="evenodd">
                                <circle cx="15" cy="15" r="15" fill="#000" opacity="1"/>
                                <path fill="#FFF" d="M21 10.2L19.8 9 15 13.8 10.2 9 9 10.2l4.8 4.8L9 19.8l1.2 1.2 4.8-4.8 4.8 4.8 1.2-1.2-4.8-4.8z"/>
                            </g>
                        </svg>
                    </div>
                     <div class="modal-content" :style="{'max-height': `${maxHeight}`}">
                        <div v-if="showHeader" class="modal-header">
                            <slot name="header"></slot>
                        </div>
                        <div class="modal-body scroll-verticle" :style="{'border-radius': `${radius}`}">
                            <slot name="body">default body</slot>
                        </div>
                    </div>
                    <div v-if="showFooter" class="modal-footer">
                        <slot name="footer">
                            Footer content
                            <!-- <button class="modal-default-button" @click="$emit('close')">OK</button> -->
                        </slot>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
export default {
  props: {
    modalType: {
      type: String,
      default: "modal2",
    },
    show: {
      type: Boolean,
      default: false,
    },
    fixedFooter: {
      type: Boolean,
      default: false,
    },
    closeable: {
      type: Boolean,
      default: true,
    },
    showHeader: {
      type: Boolean,
      default: false,
    },
    showFooter: {
      type: Boolean,
      default: false,
    },
    showCloseBtn: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: "fit-content",
    },
    MaxWidth: {
      type: String,
      default: "fit-content",
    },
    height: {
      type: String,
      default: "fit-content",
    },
    fullScreen: {
      type: Boolean,
      default: false,
    },
    padding: {
      type: String,
    },
    radius: {
      type: String,
    },
  },
  watch: {
    show(val) {
      if (val) {
        document.body.classList.add("noScroll");
      } else {
        document.body.classList.remove("noScroll");
      }
    },
  },
  data() {
    return {
      // maxHeight: this.fixedFooter ? `${document.documentElement.clientHeight * 0.7}px` : "",
    };
  },
  computed: {
    maxHeight() {
      let maxHeight = "";
      if (this.fixedFooter) {
        maxHeight = `${document.documentElement.clientHeight * 0.7}px`;
      }
      if (this.fullScreen) {
        maxHeight = `${document.documentElement.clientHeight - 64}px`;
      }
      return maxHeight;
    },
  },
  methods: {
    closeModal() {
      if (this.closeable) {
        this.$emit("close");
      }
    },
  },
};
</script>

<style scoped type="scss">
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: table;
  transition: opacity 0.3s ease;
  -webkit-transition: opacity 0.3s ease;
  -moz-transition: opacity 0.3s ease;
  -o-transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
    max-width: 100%;
    width: 1172px;
  /* width: fit-content; */
  margin: 0px auto;
  padding: 0;
  background-color: #fff;
  border-radius: 5px;
  /* box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33); */
  box-shadow: 0 5.5px 7.5px 0 rgba(0, 0, 0, 0.2), 0 4.5px 23px 4px rgba(0, 0, 0, 0.12), 0 12px 19px 1.5px rgba(0, 0, 0, 0.14);
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  color: #000;
}
.scroll-verticle {
    overflow-y: scroll;
    overflow-x: hidden;
    white-space: nowrap;
}

.scroll-verticle::-webkit-scrollbar {
    width: 0px;
}

.scroll-verticle::-webkit-scrollbar-track {
    background-color: #ececec;
    border-radius: 5px;
}

.scroll-verticle::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 5px;
}
.modal-body {
  max-height: 560px;
  overflow-y: scroll;
}

.modal-header, .modal-footer {
  /* padding: 2px 10px; */
}

.modal-header {
    text-align: left;
    border-radius: 10px 10px 0 0;
    /* border-bottom: 1px solid #ddd; */
    font-weight: 700;
    color: #242424;

    position: sticky;
    width: 100%;
    top: 0;
    background: #fff;
    z-index: 2;
}

.modal-footer {
    text-align: right;
    border-radius: 0 0 10px 10px;
    color: #fff;
    padding-bottom: 10px;
}

.modal-closeBtn {
    float: right;
    position: relative;
    margin: -12px;
    cursor: pointer;

    z-index: 3;
}
.modal-closeBtn svg {
    border: 2px solid #fff;
    border-radius: 50%;
    box-shadow: -1px 2px 5px 0px #00000080;
}
.modal-fullScreen {
    height: 100%;
    border-radius: 0;
}
.modal-fixed-footer {
    padding-top: 8px;
}
.modal-fixed-footer .modal-content {
    overflow-y: scroll;
    width: 100%;
}

.modal-fixed-footer .modal-footer {
    border-top: 1px solid #0000001a;
    padding: 8px;
}

/* Transition for modal */
.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
  -ms-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -o-transform: scale(1.1);
}

.mslide-enter {
  opacity: 0;
}

.mslide-leave-active {
  opacity: 0;
}

.mslide-enter .modal-container,
.mslide-leave-active .modal-container {
    -webkit-transform: translateY(200px);
    transform: translateY(200px);
    -ms-transform: translateY(200px);
    -moz-transform: translateY(200px);
    -o-transform: translateY(200px);
}
@media screen and (max-width: 992px){
  .modal-container {
    width: 80% !important;
  }
}
</style>
