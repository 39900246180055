<template>
  <div>
    <div class="flex teacher-dashboard mt-31">
      <!-- left section -->
      <div class="left-section white-bgc card-shadow card-border card-radius">
        <!-- Uppcoming classes -->
        <div>
          <h1 class="black-text fs-24 fw-700 mb-24">
            Welcome, <span class="fw-400">{{ userName }}!</span>
          </h1>
          <div class="flex flex-between items-baseline" style="margin-bottom: 16px">
            <h3 class="fs-15 fw-700 purple-text">Summary</h3>
          </div>
          <div>
            <div class="class-card relative flex items-center flex-between">
              <div>
                <p class="fs-12 fw-600 skyblue-text">Batches</p>
              </div>
              <div style="">
                <p class="fw-600 black-text" style="font-size: 25px; width: 64px; text-align: left">
                  {{ batchcount }}
                </p>
              </div>
              <div style="">
                <a @click="goBatch()" class="fs-11 fw-600 skyblue-text"
                  ><i class="material-icons">east</i></a
                >
              </div>
            </div>
          </div>
          <div>
            <div class="class-card relative flex items-center flex-between">
              <div>
                <p class="fs-12 fw-600 skyblue-text">Students</p>
              </div>
              <div style="">
                <p class="fw-600 black-text" style="font-size: 25px; width: 70px; text-align: left">
                  {{ studentcount }}
                </p>
              </div>
              <div style="">
                <a class="fs-11 fw-600 skyblue-text" @click="goStudent()"
                  ><i class="material-icons">east</i></a
                >
              </div>
            </div>
          </div>
          <div>
            <div class="class-card relative flex items-center flex-between">
              <div>
                <p class="fs-12 fw-600 skyblue-text">Roles</p>
              </div>
              <div style="">
                <p class="fw-600 black-text" style="font-size: 25px; width: 50px; text-align: left">
                  {{ roleCount }}
                </p>
              </div>
              <div style="">
                <a class="fs-11 fw-600 skyblue-text" @click="goUser()">
                  <i class="material-icons">east</i></a
                >
              </div>
            </div>
          </div>
          <div
            class="flex flex-between items-baseline"
            style="margin-bottom: 16px; margin-top: 25px"
          >
            <h3 class="fs-15 fw-700 purple-text">Upcoming classes</h3>
            <a style="line-height: 21px" class="yellow-btn w-105" @click="scheduleNewClass()"
              >New Class</a
            >
          </div>
          <!-- Cards -->
          <div
            :style="
              showAllUpcomingClass
                ? 'height: auto; overflow: visible;'
                : 'max-height: 189px; overflow: hidden;'
            "
          >
            <div
              style="height: 55px !important"
              class="class-card relative flex items-center flex-between"
              v-for="(upcomingClass, index) in upcomingClassData"
              :key="index"
            >
              <div>
                <p class="fs-12 fw-600 skyblue-text">
                  {{ formatDate(upcomingClass.StartTime) }}
                </p>
                <p class="fs-11 fw-400" style="color: #666666">
                  {{ formatTime1(upcomingClass.StartTime) }} -
                  {{ endTime(upcomingClass.StartTime, upcomingClass.Duration) }}
                </p>
              </div>
              <div style="text-align: left; width: 100px">
                <p
                  class="fs-11 fw-600 black-text"
                  :title="`Batch: ${upcomingClass.BatchName.toString()}`"
                >
                  {{ shortenedBatchName(upcomingClass.BatchName) }}
                </p>
                <p
                  class="fs-11 fw-600 black-text"
                  :title="`Faculty Name: ${upcomingClass.FacultyName}, Subject Name: ${upcomingClass.SubjectName}`"
                >
                  {{ upcomingClass.FacultyName }} {{ upcomingClass.SubjectName }}
                </p>
              </div>
              <i
                class="material-icons block purple-text fs-12 pointer"
                @click="
                  isClassPopupOpen = !isClassPopupOpen;
                  upcomingClassIndex = index + 1;
                "
                >more_horiz</i
              >
              <div
                class="popup flex flex-column"
                v-if="isClassPopupOpen && upcomingClassIndex === index + 1"
                v-click-outside="onClickOutside"
              >
                <a
                  class="dark-text fs-12 fw-600 active"
                  @click="startClass(upcomingClass)"
                  :class="
                    getTimeDiff(
                      upcomingClass.StartTime,
                      upcomingClass.Duration,
                      upcomingClass.StartTime,
                    )
                  "
                  >Start</a
                >
                <a class="dark-text fs-12 fw-600" @click="cancelLiveClass(upcomingClass.Id)"
                  >Cancel</a
                >
                <a class="dark-text fs-12 fw-600" @click="reschedule(upcomingClass)">Reschedule</a>
              </div>
            </div>
            <!--<div class="class-card relative flex items-center flex-between">
                          <div>
                              <p class="fs-12 fw-600 skyblue-text">17 May ‘22</p>
                              <p class="fs-11 fw-400" style="color: #666666;">04:00 pm - 05:00 pm</p>
                          </div>
                          <div>
                              <p class="fs-12 fw-600 black-text">10 CBSE</p>
                              <p class="fs-12 fw-600 black-text">Maths</p>
                          </div>
                          <i class="material-icons block purple-text fs-12 pointer" @click="isClassPopupOpen = !isClassPopupOpen">more_horiz</i>
                          <div class="popup flex flex-column" v-if="isClassPopupOpen" v-click-outside="onClickOutside">
                              <a class="dark-text fs-12 fw-600 active" style="margin-bottom: 5px;" @click="isClassPopupOpen = false">Start</a>
                              <a class="dark-text fs-12 fw-600" style="margin-bottom: 5px;" @click="isClassPopupOpen = false">Cancel</a>
                              <a class="dark-text fs-12 fw-600" style="margin-bottom: 5px;" @click="isClassPopupOpen = false">Rechedule</a>
                          </div>
                      </div> -->
          </div>
          <a
            class="fs-11 fw-600 skyblue-text flex items-center max-content"
            v-if="upcomingClassData.length > 3"
            @click="showAllUpcomingClass = !showAllUpcomingClass"
            ><span>{{ showAllUpcomingClass ? "View less" : "View all" }}</span>
            <i class="material-icons fs-12">chevron_right</i>
          </a>
        </div>
        <!-- Calender -->
        <div style="margin-top: 27px" class="calender-sec">
          <div class="flex flex-between items-center" style="margin-bottom: 16px">
            <h3 class="fs-15 fw-700 purple-text">Calendar</h3>
            <!-- <a class="yellow-btn w-105">Detailed View</a> -->
          </div>
          <div class="calender-body">
            <v-calendar
              class="calender-box"
              :attributes="attributes"
              @dayclick="selectDate"
              title-position="left"
              is-expanded
              is-range
            ></v-calendar>
            <!-- <v-calendar class="calender-box" :attributes="attributes" @dayclick="selectDate" title-position="left" is-expanded is-range  v-click-outside=onClickOutsideCalendar></v-calendar> -->

            <div class="scroll-verticle-bar" style="max-height: 300px">
              <div
                class="events relative flex items-center"
                v-for="(scheduleClass, index) in upcomingCalendarData"
                :key="index"
              >
                <img
                  :src="require(`../../assets/liveClass.svg`)"
                  alt=""
                  v-if="scheduleClass.Type === 'OnlineClass'"
                />
                <img
                  :src="require(`../../assets/testIcon.svg`)"
                  alt=""
                  v-if="scheduleClass.Type === 'Test'"
                />
                <img
                  :src="require(`../../assets/assignmentIcon.svg`)"
                  alt=""
                  v-if="scheduleClass.Type === 'Assignment'"
                />
                <div>
                  <p
                    class="fs-11 fw-400"
                    style="color: #666666; margin-left: 8px"
                    v-if="scheduleClass.Type === 'Assignment' || scheduleClass.Type === 'Test'"
                  >
                    {{ formatTime1(scheduleClass.StartDate) }} -
                    {{ formatTime1(scheduleClass.EndDate) }}
                  </p>
                  <p
                    class="fs-11 fw-400"
                    style="color: #666666; margin-left: 8px"
                    v-if="scheduleClass.Type === 'OnlineClass'"
                  >
                    {{ formatTime1(scheduleClass.StartDate) }} -
                    {{ endTime(scheduleClass.StartDate, scheduleClass.Duration) }}
                  </p>
                  <p class="skyblue-text fs-12 fw-600" style="color: #666666; margin-left: 8px">
                    {{ selectedSubName }}, {{ selectedCourseName }}
                  </p>
                </div>
                <!-- <a class="yellow-btn w-75" style="line-height: 21px;">Add Event</a> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Main Container -->
      <div class="main-container">
        <div class="top-box flex">
          <div class="middle-section">
            <div class="flex flex-between items-center">
              <div class="flex items-center flex-wrap">
                <a
                  class="head-box fs-12 skyblue-text flex items-center flex-between"
                  @click="showSelectBox = !showSelectBox"
                >
                  Select
                  <i class="material-icons fs-15">{{
                    showSelectBox ? "expand_less" : "expand_more"
                  }}</i>
                </a>
                <p class="fs-18 fw-700 dark-text uppercase truncate" style="max-width: 100px">
                  {{ selectedCourseName }}
                </p>
                <i
                  class="material-icons fs-15"
                  style="padding: 0 5px"
                  :class="{ hide: !selectedCourseName || !selectedBatchName }"
                  >keyboard_arrow_right</i
                >
                <p
                  class="fs-18 fw-700 dark-text capitalize truncate"
                  style="max-width: 100px"
                  :title="selectedBatchName"
                >
                  {{ selectedBatchName }}
                </p>
                <i
                  class="material-icons fs-15"
                  style="padding: 0 5px"
                  :class="{ hide: !selectedSubName }"
                  >keyboard_arrow_right</i
                >
                <p class="fs-18 fw-700 purple-text capitalize truncate" style="max-width: 100px">
                  {{ selectedSubName }}
                </p>
              </div>
              <input
                type="text"
                id="search"
                value=""
                placeholder="Search"
                class="head-box"
                @click="
                  showSearchBox = true;
                  showSelectBox = false;
                "
                readonly
              />
            </div>
            <!-- search content -->
            <div
              class="search-content scroll-verticle"
              v-if="showSearchBox"
              v-click-outside="onClickOutside"
            >
              <input
                type="text"
                id="search2"
                placeholder="Search from Class, Batch, Subject..."
                class="head-box"
                style="width: 100% !important"
                v-model="searchValue"
              />
              <div class="search-item scroll-verticle">
                <div
                  class="search-item-content"
                  v-for="(course, index) in filteredList"
                  :key="index"
                >
                  <!-- <p>{{item}}</p> -->
                  <h2
                    :class="{ active: selectedCourseName === course.CourseName }"
                    @click="selectCourse(course.CourseId, course.CourseName)"
                  >
                    {{ course.CourseName }}
                  </h2>
                  <!-- <h2 class="active">{{course.CourseName}}</h2> -->
                  <ul v-for="(batch, index) in course.BatchDetails" :key="index">
                    <li class="flex items-center subBatchList">
                      <span
                        class="batch"
                        @click="
                          selectBatch(
                            batch.CenterId,
                            batch.Name,
                            course,
                            batch.subjectDetails[0],
                            batch.ClassId,
                          )
                        "
                        :class="{
                          active: batch.Name === selectedBatchName && selectedCourseName !== '',
                        }"
                        >{{ batch.Name }}
                      </span>
                      <div
                        v-dragscroll
                        class="scroll-horizontal subject-item"
                        style="height: 16px; margin-left: 5px"
                      >
                        <h5
                          class="fs-14 subject"
                          :class="{
                            active:
                              subject.SubjectName === selectedSubName &&
                              batch.Name === selectedBatchName &&
                              selectedCourseName !== '',
                          }"
                          v-for="(subject, index) in batch.subjectDetails[0]"
                          :key="index"
                          @click="
                            selectSubject(subject.SubjectId, subject.SubjectName, batch, course)
                          "
                        >
                          {{ subject.SubjectName }}
                        </h5>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <!-- Report content -->
            <div
              class="white-bgc card-border p-15 reports"
              :style="showSearchBox || showSelectBox ? 'opacity: 0' : ''"
            >
              <h1 class="fs-15 fw-700 purple-text" style="margin-bottom: 18px">Reports</h1>
              <ul class="flex items-center report-link">
                <li
                  class="dark-text fs-12 fw-400"
                  :class="reportTab === 1 ? 'active' : ''"
                  @click="reportTab = 1"
                >
                  Batch Wise
                  <i class="material-icons fs-15">{{ reportTab === 1 ? "expand_less" : "" }}</i>
                </li>
                <li
                  class="dark-text fs-12 fw-400"
                  :class="reportTab === 2 ? 'active' : ''"
                  @click="reportTab = 2"
                >
                  Subject Wise
                  <i class="material-icons fs-15">{{ reportTab === 2 ? "expand_less" : "" }}</i>
                </li>
                <li
                  class="dark-text fs-12 fw-400"
                  :class="reportTab === 3 ? 'active' : ''"
                  @click="reportTab = 3"
                >
                  Test
                  <i class="material-icons fs-15">{{ reportTab === 3 ? "expand_less" : "" }}</i>
                </li>
              </ul>
              <div class="report-contents batchWise" v-if="reportTab === 1">
                <div class="report-card effort-analysis">
                  <h3>Effort Analysis</h3>
                  <ul
                    class="flex truncate"
                    v-if="selectedCourseName && selectedBatchName && selectedSubName"
                  >
                    <!-- <li>{{ selectedCourseName.length > 9
                              ?
                              selectedCourseName.slice(0, 9) + ".." : selectedCourseName
                          }}</li>
                              <li>{{ selectedBatchName.length > 12
                              ?
                              selectedBatchName.slice(0, 12) + ".." : selectedBatchName
                          }}</li>
                              <li>{{selectedSubName}}</li> -->
                    <li class="truncate" style="max-width: 100px" :title="selectedCourseName">
                      {{ selectedCourseName }}
                    </li>
                    <li class="truncate" style="max-width: 100px" :title="selectedBatchName">
                      {{ selectedBatchName }}
                    </li>
                    <li class="truncate" style="max-width: 100px" :title="selectedSubName">
                      {{ selectedSubName }}
                    </li>
                  </ul>
                  <p class="fs-11 dark-text fw-400" style="opacity: 0.7">
                    Analyze the performance and effort of each student over the last few weeks.
                  </p>
                  <div class="flex flex-between items-center" style="margin-top: 6px">
                    <a
                      class="yellow-btn w-69"
                      @click="viewEffortAnalysis()"
                      v-if="selectedCourseName && selectedBatchName && selectedSubName"
                      >View</a
                    >
                    <img :src="require(`../../assets/book (2) 1.svg`)" class="bright-img" />
                  </div>
                </div>
                <div class="report-card performance-trend">
                  <h3>Batch Performance</h3>
                  <ul
                    class="flex"
                    v-if="selectedCourseName && selectedBatchName && selectedSubName"
                  >
                    <li class="truncate" style="max-width: 100px" :title="selectedCourseName">
                      {{ selectedCourseName }}
                    </li>
                    <li class="truncate" style="max-width: 100px" :title="selectedBatchName">
                      {{ selectedBatchName }}
                    </li>
                    <li class="truncate" style="max-width: 100px" :title="selectedSubName">
                      {{ selectedSubName }}
                    </li>
                  </ul>
                  <p class="fs-11 dark-text fw-400" style="opacity: 0.7">
                    Review an entire batch's progress with student-wise reports and tailored
                    recommendations.
                  </p>
                  <div class="flex flex-between items-center" style="margin-top: 6px">
                    <a
                      class="yellow-btn w-69"
                      v-if="selectedCourseName && selectedBatchName && selectedSubName"
                      @click="goBatchReport()"
                      >View</a
                    >
                    <img :src="require(`../../assets/performance 1.svg`)" class="bright-img" />
                  </div>
                </div>
                <div class="report-card performance-trend hide">
                  <h3>Performance trend</h3>
                  <ul
                    class="flex"
                    v-if="selectedCourseName && selectedBatchName && selectedSubName"
                  >
                    <li class="truncate" style="max-width: 100px" :title="selectedCourseName">
                      {{ selectedCourseName }}
                    </li>
                    <li class="truncate" style="max-width: 100px" :title="selectedBatchName">
                      {{ selectedBatchName }}
                    </li>
                    <li class="truncate" style="max-width: 100px" :title="selectedSubName">
                      {{ selectedSubName }}
                    </li>
                  </ul>
                  <p class="fs-11 dark-text fw-400" style="opacity: 0.7">
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                  </p>
                  <div class="flex flex-between items-center" style="margin-top: 6px">
                    <a
                      class="yellow-btn w-69"
                      v-if="selectedCourseName && selectedBatchName && selectedSubName"
                      >View</a
                    >
                    <img :src="require(`../../assets/performance 1.svg`)" class="bright-img" />
                  </div>
                </div>
                <div class="report-card top-performers hide">
                  <h3>Top performers analysis</h3>
                  <ul
                    class="flex"
                    v-if="selectedCourseName && selectedBatchName && selectedSubName"
                  >
                    <li class="truncate" style="max-width: 100px" :title="selectedCourseName">
                      {{ selectedCourseName }}
                    </li>
                    <li class="truncate" style="max-width: 100px" :title="selectedBatchName">
                      {{ selectedBatchName }}
                    </li>
                    <li class="truncate" style="max-width: 100px" :title="selectedSubName">
                      {{ selectedSubName }}
                    </li>
                  </ul>
                  <p class="fs-11 dark-text fw-400" style="opacity: 0.7">
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                  </p>
                  <div class="flex flex-between items-center" style="margin-top: 6px">
                    <a
                      class="yellow-btn w-69"
                      v-if="selectedCourseName && selectedBatchName && selectedSubName"
                      >View</a
                    >
                    <img :src="require(`../../assets/leader 1.svg`)" class="bright-img" />
                  </div>
                </div>
                <div class="report-card detailed-students hide">
                  <h3 class="truncate">Detailed students report card</h3>
                  <ul
                    class="flex"
                    v-if="selectedCourseName && selectedBatchName && selectedSubName"
                  >
                    <li class="truncate" style="max-width: 100px" :title="selectedCourseName">
                      {{ selectedCourseName }}
                    </li>
                    <li class="truncate" style="max-width: 100px" :title="selectedBatchName">
                      {{ selectedBatchName }}
                    </li>
                    <li class="truncate" style="max-width: 100px" :title="selectedSubName">
                      {{ selectedSubName }}
                    </li>
                  </ul>
                  <p class="fs-11 dark-text fw-400" style="opacity: 0.7">
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                  </p>
                  <div class="flex flex-between items-center" style="margin-top: 6px">
                    <a
                      class="yellow-btn w-69"
                      v-if="selectedCourseName && selectedBatchName && selectedSubName"
                      >View</a
                    >
                    <img :src="require(`../../assets/graph-report 1.svg`)" class="bright-img" />
                  </div>
                </div>
              </div>
              <div class="report-contents subjectWise" v-if="reportTab === 2">
                <div class="report-card effort-analysis">
                  <h3>Subject coverage</h3>
                  <ul
                    class="flex"
                    v-if="selectedCourseName && selectedBatchName && selectedSubName"
                  >
                    <li class="truncate" style="max-width: 100px" :title="selectedCourseName">
                      {{ selectedCourseName }}
                    </li>
                    <li class="truncate" style="max-width: 100px" :title="selectedBatchName">
                      {{ selectedBatchName }}
                    </li>
                    <li class="truncate" style="max-width: 100px" :title="selectedSubName">
                      {{ selectedSubName }}
                    </li>
                  </ul>
                  <p class="fs-11 dark-text fw-400" style="opacity: 0.7">
                    Analyze the chapter-wise coverage of a batch for any subject.
                  </p>
                  <div class="flex flex-between items-center" style="margin-top: 6px">
                    <a
                      class="yellow-btn w-69"
                      @click="viewSubCoverage()"
                      v-if="selectedCourseName && selectedBatchName && selectedSubName"
                      >View</a
                    >
                    <img :src="require(`../../assets/books 1.svg`)" class="bright-img" />
                  </div>
                </div>
                <div class="report-card performance-trend hide">
                  <h3>Performance trend</h3>
                  <ul
                    class="flex"
                    v-if="selectedCourseName && selectedBatchName && selectedSubName"
                  >
                    <li class="truncate" style="max-width: 100px" :title="selectedCourseName">
                      {{ selectedCourseName }}
                    </li>
                    <li class="truncate" style="max-width: 100px" :title="selectedBatchName">
                      {{ selectedBatchName }}
                    </li>
                    <li class="truncate" style="max-width: 100px" :title="selectedSubName">
                      {{ selectedSubName }}
                    </li>
                  </ul>
                  <p class="fs-11 dark-text fw-400" style="opacity: 0.7">
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                  </p>
                  <div class="flex flex-between items-center" style="margin-top: 6px">
                    <a
                      class="yellow-btn w-69"
                      v-if="selectedCourseName && selectedBatchName && selectedSubName"
                      >View</a
                    >
                    <img :src="require(`../../assets/brainstorming 1.svg`)" class="bright-img" />
                  </div>
                </div>
              </div>
              <div class="report-contents Test" v-if="reportTab === 3">
                <div class="report-card effort-analysis">
                  <h3>Institute test analysis</h3>
                  <ul
                    class="flex"
                    v-if="selectedCourseName && selectedBatchName && selectedSubName"
                  >
                    <li class="truncate" style="max-width: 100px" :title="selectedCourseName">
                      {{ selectedCourseName }}
                    </li>
                    <li class="truncate" style="max-width: 100px" :title="selectedBatchName">
                      {{ selectedBatchName }}
                    </li>
                    <li class="truncate" style="max-width: 100px" :title="selectedSubName">
                      {{ selectedSubName }}
                    </li>
                  </ul>
                  <p class="fs-11 dark-text fw-400" style="opacity: 0.7">
                    Detailed results and analysis of students' performance in the institute test.
                  </p>
                  <div class="flex flex-between items-center" style="margin-top: 6px">
                    <a
                      class="yellow-btn w-69"
                      @click="viewTestAnalysis()"
                      v-if="selectedCourseName && selectedBatchName && selectedSubName"
                      >View</a
                    >
                    <img :src="require(`../../assets/report 1.svg`)" class="bright-img" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Select Box content-->
          <div
            class="select-box scroll-verticle"
            v-if="showSelectBox"
            v-click-outside="onClickOutside"
          >
            <div class="select-content" v-for="(course, index) in selectOptionData" :key="index">
              <h3
                :class="{ active: selectedCourseName === course.CourseName }"
                @click="selectCourse(course.CourseId, course.CourseName)"
                v-if="course.BatchDetails.length > 0"
                :title="course.CourseName"
              >
                {{
                  course.CourseName.length > 10
                    ? course.CourseName.slice(0, 9) + ".."
                    : course.CourseName
                }}
              </h3>
              <div
                class="subject-items"
                :class="{ active: selectedCourseName === course.CourseName }"
                v-if="course.BatchDetails.length > 0"
              >
                <div
                  class="right-content"
                  v-for="(batch, index) in course.BatchDetails"
                  :key="index"
                >
                  <h4
                    @click="
                      selectBatch(
                        batch.CenterId,
                        batch.Name,
                        course,
                        batch.subjectDetails[0],
                        batch.ClassId,
                      )
                    "
                    :class="{
                      active: batch.Name === selectedBatchName && selectedCourseName !== '',
                    }"
                    :title="batch.Name"
                  >
                    {{ batch.Name.length > 17 ? batch.Name.slice(0, 17) + ".." : batch.Name }}
                  </h4>
                  <div v-dragscroll class="right-subject-box scroll-horizontal flex-wrap">
                    <h5
                      class="right-subject"
                      :class="{
                        active:
                          subject.SubjectName === selectedSubName &&
                          batch.Name === selectedBatchName &&
                          selectedCourseName !== '',
                      }"
                      v-for="(subject, index) in batch.subjectDetails[0]"
                      :key="index"
                      @click="selectSubject(subject.SubjectId, subject.SubjectName, batch, course)"
                      :title="subject.SubjectName"
                    >
                      {{ subject.SubjectName }}
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- leaderboard -->
          <div class="leader-sec" v-if="leaderBoardData.length > 0">
            <div class="right-section">
              <div class="leaderboard_reward-section">
                <div class="leaderboard">
                  <div class="flex flex-between items-center" style="margin-bottom: 11px">
                    <h1 class="fs-18 fw-700 dark-text top-header">Leaderboard</h1>
                    <a
                      class="fs-11 fw-600 skyblue-text flex items-center flex-between"
                      @click="showleaderboardDetail = !showleaderboardDetail"
                      style="margin-right: 3px"
                      >Details<i class="material-icons fs-15" style="margin-left: 3px">{{
                        showleaderboardDetail ? "expand_less" : "expand_more"
                      }}</i></a
                    >
                  </div>
                  <div class="white-bgc card-radius card-shadow card-border leader-card">
                    <div class="leader-body scroll-verticle" v-if="showleaderboardDetail === false">
                      <div
                        class="flex leader-top items-center flex-between"
                        v-for="(Leaders, index) in leaderBoardData"
                        :key="index"
                      >
                        <div class="flex">
                          <ProfileAvatar
                            style="border: none"
                            :username="Leaders.Name.charAt(0)"
                            class="leader-avatar"
                            :bgColor="leaderBg[index] + '!important'"
                          ></ProfileAvatar>
                          <h3
                            class="fs-12 black-text fw-600"
                            style="white-space: nowrap; line-height: 20px"
                          >
                            {{
                              Leaders.Name.length > 16
                                ? Leaders.Name.slice(0, 16) + "..."
                                : Leaders.Name
                            }}
                          </h3>
                        </div>
                        <h3 class="fs-12 skyblue-text fw-600 relative" style="left: -15px">
                          {{ index + 1 }}
                        </h3>
                      </div>
                    </div>
                    <div
                      class="leader-body scroll-verticle leader-details"
                      v-if="showleaderboardDetail === true"
                    >
                      <div
                        class="leader-top"
                        v-for="(Leaders, index) in leaderBoardData"
                        :key="index"
                      >
                        <div class="flex items-center flex-between">
                          <div class="flex">
                            <ProfileAvatar
                              style="border: none"
                              :username="Leaders.Name.charAt(0)"
                              class="leader-avatar"
                              :bgColor="leaderBg[index] + '!important'"
                            ></ProfileAvatar>
                            <h3
                              class="fs-12 black-text fw-600"
                              style="white-space: nowrap; line-height: 20px"
                            >
                              {{
                                Leaders.Name.length > 16
                                  ? Leaders.Name.slice(0, 16) + "..."
                                  : Leaders.Name
                              }}
                            </h3>
                          </div>
                          <h3
                            class="fs-12 skyblue-text fw-600 relative"
                            style="left: -15px; line-height: 20px"
                          >
                            {{ index + 1 }}
                          </h3>
                        </div>
                        <div class="flex" style="margin-left: 28px">
                          <p
                            class="flex fs-10 dark-text fw-600 leader-para"
                            v-if="!showSortedLeaderboard"
                          >
                            <img
                              :src="require(`../../assets/Question.svg`)"
                              style="margin-right: 8px"
                            />
                            <span style="opacity: 0.4">{{ Leaders.Expertise }}</span>
                          </p>
                          <p
                            class="flex fs-10 dark-text fw-600 leader-para"
                            v-if="showSortedLeaderboard"
                          >
                            <img
                              :src="require(`../../assets/Question.svg`)"
                              style="margin-right: 8px"
                            />
                            <span style="opacity: 0.4">{{
                              Leaders[LeaderAttempted] || Leaders[LeaderAttempted] === 0
                                ? Leaders[LeaderAttempted]
                                : Leaders.Attempted
                            }}</span>
                          </p>
                          <p
                            class="flex fs-10 black-text fw-600 leader-para"
                            v-if="!showSortedLeaderboard"
                          >
                            <img
                              :src="require(`../../assets/target (1) 1.svg`)"
                              style="margin-right: 8px"
                            /><span style="opacity: 0.4">{{ Leaders.Accuracy }}</span>
                          </p>
                          <p
                            class="flex fs-10 black-text fw-600 leader-para"
                            v-if="showSortedLeaderboard"
                          >
                            <img
                              :src="require(`../../assets/target (1) 1.svg`)"
                              style="margin-right: 8px"
                            /><span style="opacity: 0.4">{{
                              Leaders[LeaderAccuracy] || Leaders[LeaderAttempted] === 0
                                ? Leaders[LeaderAccuracy]
                                : Leaders.Accuracy
                            }}</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="recommedation"> -->
        <div
          class="recommend-sec"
          v-if="
            extraClassData.length > 0 || assignmentData.length > 0 || InstituteTestData.length > 0
          "
        >
          <h3 class="fs-18 fw-700 dark-text" style="margin-bottom: 9px">Recommendations</h3>
          <!-- Extra Classses -->
          <div
            class="recommend-content white-bgc card-radius card-border card-shadow"
            v-if="extraClassData.length > 0"
          >
            <div class="recommendation extra-class">
              <div class="recommend-top flex items-center">
                <h2 class="fs-15 purple-text fw-700" style="margin-bottom: 5px; margin-right: 45px">
                  Extra Classes
                </h2>
              </div>
              <div
                v-dragscroll
                v-if="this.extraClassData.length !== 0"
                class="recommendation-content flex scroll-horizontal"
              >
                <div
                  class="main-recommendation-box"
                  v-for="(ecData, index) in extraClassData"
                  :key="index"
                  :value="ecData.chapterId"
                >
                  <ClassCard
                    :label1="ecData.chapterName"
                    :label2="ecData.subjectName"
                    :subjectIcon="ecData.subjectIcon"
                    :buttonName="'Add'"
                    :backgroundColor="'#FFECDB'"
                    :label3="selectedBatchName"
                    :data="ecData"
                    @cardData="scheduleClass($event)"
                  />
                </div>
                <div class="main-recommendation-box">
                  <div
                    class="recommend-box recommendation-box relative lightPink-bgc card-radius"
                    style="padding: 14px; overflow: hidden; background: #e3e3e3 !important"
                  >
                    <div class="flex flex-between flex-column">
                      <img
                        style="height: 34px; margin-bottom: 6px; cursor: pointer"
                        :src="require(`../../assets/newsession.svg`)"
                        @click="scheduleNewClass()"
                      />
                      <p class="fs-9 fw-600 center dark-text">Schedule an Extra Class</p>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="this.extraClassData.length === 0">
                <div class="main-recommendation-box">
                  <div
                    class="recommend-box recommendation-box relative lightPink-bgc card-radius"
                    style="padding: 14px; overflow: hidden; background: #e3e3e3 !important"
                  >
                    <div class="flex flex-between flex-column">
                      <img
                        style="height: 34px; margin-bottom: 6px; cursor: pointer"
                        :src="require(`../../assets/newsession.svg`)"
                        @click="scheduleNewClass()"
                      />
                      <p class="fs-9 fw-600 center dark-text">Schedule an Extra Class</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Assignments -->
          <div
            class="recommend-content white-bgc card-radius card-border card-shadow"
            v-if="assignmentData.length > 0"
          >
            <div class="recommendation extra-class">
              <div class="recommend-top flex items-center">
                <h2 class="fs-15 purple-text fw-700" style="margin-bottom: 5px; margin-right: 45px">
                  Assignments
                </h2>
              </div>
              <div
                v-dragscroll
                v-if="this.assignmentData.length !== 0"
                class="recommendation-content flex scroll-horizontal"
              >
                <div
                  class="main-recommendation-box"
                  v-for="(assiData, index) in assignmentData"
                  :key="index"
                  :value="assiData.chapterId"
                >
                  <ClassCard
                    :label1="assiData.chapterName"
                    :label2="assiData.subjectName"
                    :subjectIcon="assiData.subjectIcon"
                    :buttonName="'Create'"
                    :backgroundColor="'#ebffe7'"
                    :label3="selectedBatchName"
                    :data="assiData"
                    @cardData="createAssignment($event)"
                  />
                </div>
                <div class="main-recommendation-box">
                  <div
                    class="recommend-box recommendation-box relative lightPink-bgc card-radius"
                    style="padding: 14px; overflow: hidden; background: #e3e3e3 !important"
                  >
                    <div class="flex flex-between flex-column">
                      <img
                        style="height: 34px; margin-bottom: 6px; cursor: pointer"
                        :src="require(`../../assets/newsession.svg`)"
                        @click="CreateNewAssignment()"
                      />
                      <p class="fs-9 fw-600 center dark-text">Create an Assignment</p>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="this.assignmentData.length === 0">
                <div class="main-recommendation-box">
                  <div
                    class="recommend-box recommendation-box relative lightPink-bgc card-radius"
                    style="padding: 14px; overflow: hidden; background: #e3e3e3 !important"
                  >
                    <div class="flex flex-between flex-column">
                      <img
                        style="height: 34px; margin-bottom: 6px; cursor: pointer"
                        :src="require(`../../assets/newsession.svg`)"
                        @click="CreateNewAssignment()"
                      />
                      <p class="fs-9 fw-600 center dark-text">Create an Assignment</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Institute Tests -->
          <div
            class="recommend-content white-bgc card-radius card-border card-shadow"
            v-if="InstituteTestData.length > 0"
          >
            <div class="recommendation extra-class">
              <div class="recommend-top flex items-center">
                <h2 class="fs-15 purple-text fw-700" style="margin-bottom: 5px; margin-right: 45px">
                  Institute Tests
                </h2>
              </div>
              <div
                v-dragscroll
                v-if="this.InstituteTestData.length !== 0"
                class="recommendation-content flex scroll-horizontal"
              >
                <div
                  class="main-recommendation-box"
                  v-for="(testData, index) in InstituteTestData"
                  :key="index"
                  :value="testData.chapterId"
                >
                  <ClassCard
                    :label1="testData.chapterName"
                    :label2="testData.subjectName"
                    :subjectIcon="testData.subjectIcon"
                    :buttonName="'Create'"
                    :backgroundColor="`linear-gradient(98.34deg,#e9ccff 11.31%,#f4e6ff 88.13%)`"
                    :label3="selectedBatchName"
                    :data="testData"
                    @cardData="createTest(testData)"
                  />
                </div>
                <div class="main-recommendation-box">
                  <div
                    class="recommend-box recommendation-box relative lightPink-bgc card-radius"
                    style="padding: 14px; overflow: hidden; background: #e3e3e3 !important"
                  >
                    <div class="flex flex-between flex-column">
                      <img
                        style="height: 34px; margin-bottom: 6px; cursor: pointer"
                        :src="require(`../../assets/newsession.svg`)"
                        @click="CreateNewTest()"
                      />
                      <p class="fs-9 fw-600 center dark-text">Create a Test</p>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="this.InstituteTestData.length === 0">
                <div class="main-recommendation-box">
                  <div
                    class="recommend-box recommendation-box relative lightPink-bgc card-radius"
                    style="padding: 14px; overflow: hidden; background: #e3e3e3 !important"
                  >
                    <div class="flex flex-between flex-column">
                      <img
                        style="height: 34px; margin-bottom: 6px; cursor: pointer"
                        src="../../assets/newsession.svg"
                        @click="CreateNewTest()"
                      />
                      <p class="fs-9 fw-600 center dark-text">Create a Test</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Modal2 :show="CancelClassModal" :showHeader="false" :showCloseBtn="false">
      <template v-slot:body>
        <div style="padding: 15px">
          <h3 class="fs-18 fw-600">Hey, Wait!</h3>
          <p class="fs-14 fw-400">Are you sure want to cancel this live class?</p>
          <div class="flex items-center" style="margin-top: 8px">
            <a
              class="yellow-btn"
              style="margin-right: 15px; width: 50px"
              @click="cancelLiveClassPopup()"
              >Yes</a
            >
            <a
              class="yellow-btn"
              style="background: rgb(143 104 173); width: 50px"
              @click="
                CancelClassModal = false;
                isClassPopupOpen = false;
              "
              >No</a
            >
          </div>
        </div>
      </template>
    </Modal2>
    <Modal
      :show="showClassSelectionModal"
      :showHeader="false"
      @close="showClassSelectionModal = false"
    >
      <template v-slot:body>
        <h1 class="fs-18 fw-400 dark-text top-header center-align">
          Welcome <strong>{{ userName }} !!</strong>
        </h1>
        <div style="display: flex; justify-content: center">
          <img class="center-align" :src="require(`../../assets/school.png`)" />
        </div>
        <p class="center-align modal-select">
          Kindly select class for <b>{{ this.selectedBatchName }}</b>
        </p>
        <div class="center" style="width: 450px; display: flex; justify-content: space-around">
          <button
            v-for="(cls, index) in getclass"
            :key="index"
            class="modal-button pointer"
            :class="{ selected: classId === cls.id || classId === cls.Id }"
            @click="onClassChange(cls.id || cls.Id)"
          >
            {{ cls.DisplayName || cls.displayName }}
          </button>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import ProfileAvatar from "vue-profile-avatar";
import moment from "moment";
import API from "../../Api";
import Mobileapi from "../../Mobileapi";
import Multiple from "../../assets/Multiple.png";
import Modal from "../../components/Modal";
import Modal2 from "../../components/Model2";
import ClassCard from "../../components/ClassCard.vue";

export default {
  data() {
    return {
      randomImage: Multiple,
      isClassPopupOpen: false,
      markedDates: new Date().getDate().toString(),
      leaderBg: [],
      attributes: [
        {
          key: "today",
          highlight: "blue",
          dates: new Date(),
          excludeDates: null,
        },
      ],
      liveClassdata: [],
      upcomingClassData: [],
      scheduleClassData: [],
      userDetails: [],
      reportTab: 1,
      upcomingClassIndex: 1,
      showSelectBox: false,
      showSearchBox: false,
      showAllUpcomingClass: false,
      coursedata: [],
      SelectedCourseList: [],
      batchData: [],
      CourseId: null,
      classId: null,
      recommendationData: [],
      leaderBoardData: [],
      InstituteTestData: [],
      extraClassData: [],
      assignmentData: [],
      templeaderBoardData: [],
      tempInstituteTestData: [],
      tempextraClassData: [],
      tempassignmentData: [],
      selectedCourseName: "",
      selectedCourseId: null,
      selectedBatchName: "",
      selectedBatchId: null,
      selectedSubName: "",
      selectedSubId: null,
      showleaderboardDetail: false,
      selectOptionData: [],
      searchValue: "",
      searchedData: [],
      showSortedLeaderboard: false,
      LeaderAttempted: null,
      LeaderAccuracy: null,
      userName: "",
      selectedDate: "",
      upcomingCalendarData: [],
      batchcount: null,
      studentcount: null,
      roleCount: null,
      isPageChange: false,
      CancelClassModal: false,
      isClassCancel: false,
      cancelLiveClassId: null,
      getclass: [],
      showClassSelectionModal: false,
    };
  },
  // beforeCreate() {
  //   document.querySelector("body").setAttribute("style", "background:#F6F8FB");
  // },

  // beforeUnmount() {
  //   document.querySelector("body").setAttribute("style", "background:#f6f1ec");
  // },
  activated() {
    const user = this.$store.getters.user;
    this.userDetails = user;
    this.selectedDate = moment(new Date()).format("YYYY-MM-DD");
    this.$store.dispatch("showLoader", true);
    Mobileapi.batchCount(
      {
        affiliationId: user.AffiliationId,
      },
      (response) => {
        this.batchcount = response.data.BatchCount;
      },
    );
    Mobileapi.studentCount(
      {
        affiliationId: user.AffiliationId,
      },
      (response) => {
        this.studentcount = response.data.Active;
      },
    );
    Mobileapi.getAffiliationUserRoleList(user.AffiliationId, (response) => {
      this.roleCount = response.data.length;
    });
    Mobileapi.getUserProfile((userResponse) => {
      this.userName = userResponse.data.Name ? userResponse.data.Name : "";
    });
    Mobileapi.liveclassdata(
      { upcoming: 1 },
      (response) => {
        if (response.data.length > 0) {
          this.liveClassdata = response.data;
          this.upcomingClassData = response.data.filter(
            (item) => moment(item.StartTime).utc().format("YYYY-MM-DD") === this.selectedDate,
          );
          this.scheduleClassData = this.upcomingClassData;
          this.$store.dispatch("showLoader", false);
        }
      },
      () => {
        this.$store.dispatch("showLoader", false);
      },
    );
    this.$store.dispatch("showLoader", true);
    Mobileapi.getCourseBatchSubject(
      {
        AffiliationId: Number(this.userDetails.AffiliationId),
      },
      (resp) => {
        if (resp.Data.length > 0) {
          this.selectOptionData = resp.Data;
          if (this.isPageChange) {
            const localStorageData = JSON.parse(localStorage.getItem("TeacherDashboardData"));
            this.selectedCourseName = localStorageData.courseName;
            this.selectedCourseId = localStorageData.courseId;
            this.recommendationData = [];
            this.selectedBatchName = localStorageData.batchName;
            this.selectedBatchId = localStorageData.batchId;
            this.selectedSubId = localStorageData.subjectId;
            this.selectedSubName = localStorageData.subjectname;
            this.classId = localStorageData.classId;
            this.isPageChange = false;
          } else {
            this.isPageChange = false;
            this.selectedCourseName = this.selectOptionData[0].CourseName;
            this.selectedCourseId = this.selectOptionData[0].CourseId;
            this.recommendationData = [];
            this.selectedBatchName = this.selectOptionData[0].BatchDetails[0].Name;
            this.classId = this.selectOptionData[0].BatchDetails[0].ClassId;
            this.selectedBatchId = this.selectOptionData[0].BatchDetails[0].CenterId;
            this.selectedSubId =
              this.selectOptionData[0].BatchDetails[0].subjectDetails[0][0].SubjectId;
            this.selectedSubName =
              this.selectOptionData[0].BatchDetails[0].subjectDetails[0][0].SubjectName;
            localStorage.removeItem("TeacherDashboardData");
            const TeacherDashboardData = {
              courseId: this.selectedCourseId,
              courseName: this.selectedCourseName,
              batchId: this.selectedBatchId,
              batchName: this.selectedBatchName,
              subjectId: this.selectedSubId,
              subjectname: this.selectedSubName,
              classId: this.classId,
            };
            localStorage.setItem("TeacherDashboardData", JSON.stringify(TeacherDashboardData));
          }
          if (this.selectedBatchId && this.selectedCourseId) {
            this.$store.dispatch("showLoader", true);
            Mobileapi.getTeacherDashboardData(
              {
                batchIds: this.selectedBatchId,
              },
              (response) => {
                this.recommendationData = response.data;
                this.leaderBoardData = this.recommendationData.leaderboard.sort(
                  (a, b) => Number(b.Attempted) - Number(a.Attempted),
                );
                this.templeaderBoardData = this.leaderBoardData;
                this.InstituteTestData = this.recommendationData.recommendations.InstituteTest
                  ? this.recommendationData.recommendations.InstituteTest
                  : [];
                this.tempInstituteTestData = this.InstituteTestData;
                this.extraClassData = this.recommendationData.recommendations.ExtraClass
                  ? this.recommendationData.recommendations.ExtraClass
                  : [];
                this.tempextraClassData = this.extraClassData;
                this.assignmentData = this.recommendationData.recommendations.Assignment
                  ? this.recommendationData.recommendations.Assignment
                  : [];
                this.tempassignmentData = this.assignmentData;
                this.$store.dispatch("showLoader", false);
                this.extraClassData = this.tempextraClassData.filter(
                  (item) => item.subjectId === this.selectedSubId,
                );
                this.assignmentData = this.tempassignmentData.filter(
                  (item) => item.subjectId === this.selectedSubId,
                );
                this.InstituteTestData = this.tempInstituteTestData.filter(
                  (item) => item.subjectId === this.selectedSubId,
                );
                this.LeaderAttempted = `Attempted_${this.selectedSubId}`;
                this.LeaderAccuracy = `Accuracy_${this.selectedSubId}`;
                this.leaderBoardData = this.templeaderBoardData.sort(
                  (a, b) => b[this.LeaderAttempted] - a[this.LeaderAttempted],
                );
                this.showSortedLeaderboard = true;
                this.showSelectBox = false;
                this.showSearchBox = false;
                this.searchValue = "";
              },
              () => {
                this.$store.dispatch("showLoader", false);
              },
            );
          }
          this.$store.dispatch("showLoader", true);
          Mobileapi.getCalendarData(
            {
              CourseId: this.selectedCourseId,
              BatchId: this.selectedBatchId,
              SearchDate: this.selectedDate,
              AffiliationId: Number(this.userDetails.AffiliationId),
              SubjectId: this.selectedSubId,
            },
            (response) => {
              this.upcomingCalendarData = response.data;
            },
            () => {
              this.$store.dispatch("showLoader", false);
            },
          );
        }
      },
      () => {
        this.$store.dispatch("showLoader", false);
      },
    );
    API.getCourses(
      {},
      (response) => {
        if (response.data.length > 0) {
          this.coursedata = response.data;
          this.SelectedCourseList = this.coursedata.sort((a, b) => a.courseId - b.courseId);
        }
      },
      () => {
        this.$store.dispatch("showLoader", false);
      },
    );
    API.getBatches(
      this.userDetails.AffiliationId,
      (response) => {
        if (response.data.length > 0) {
          this.batchData = response.data;
        }
      },
      () => {
        this.$store.dispatch("showLoader", false);
      },
    );

    this.leaderBg = [
      "#FC8686",
      "#F0AE76",
      "#56CD9E",
      "#69B0DD",
      "#6971DD",
      "#FC8686",
      "#F0AE76",
      "#56CD9E",
      "#69B0DD",
      "#6971DD",
      "#F0AE76",
      "#FC8686",
      "#F0AE76",
      "#56CD9E",
      "#69B0DD",
      "#6971DD",
      "#F0AE76",
      "#FC8686",
      "#F0AE76",
      "#56CD9E",
      "#69B0DD",
      "#6971DD",
      "#FC8686",
      "#F0AE76",
      "#56CD9E",
      "#69B0DD",
      "#6971DD",
      "#F0AE76",
      "#FC8686",
      "#F0AE76",
      "#56CD9E",
      "#69B0DD",
      "#6971DD",
      "#F0AE76",
      "#FC8686",
      "#F0AE76",
      "#56CD9E",
      "#69B0DD",
      "#6971DD",
      "#FC8686",
      "#F0AE76",
      "#56CD9E",
      "#69B0DD",
      "#6971DD",
      "#FC8686",
      "#F0AE76",
      "#56CD9E",
      "#69B0DD",
      "#6971DD",
      "#FC8686",
      "#F0AE76",
      "#56CD9E",
      "#69B0DD",
      "#6971DD",
      "#FC8686",
      "#F0AE76",
      "#56CD9E",
      "#69B0DD",
      "#6971DD",
      "#FC8686",
      "#F0AE76",
      "#56CD9E",
      "#69B0DD",
      "#6971DD",
      "#FC8686",
      "#F0AE76",
      "#56CD9E",
      "#69B0DD",
      "#6971DD",
      "#FC8686",
      "#F0AE76",
      "#56CD9E",
      "#69B0DD",
      "#6971DD",
      "#FC8686",
      "#F0AE76",
      "#56CD9E",
      "#69B0DD",
      "#6971DD",
      "#FC8686",
      "#F0AE76",
      "#56CD9E",
      "#69B0DD",
      "#6971DD",
      "#FC8686",
      "#F0AE76",
      "#56CD9E",
      "#69B0DD",
      "#6971DD",
      "#FC8686",
      "#F0AE76",
      "#56CD9E",
      "#69B0DD",
      "#6971DD",
      "#FC8686",
      "#F0AE76",
      "#56CD9E",
      "#69B0DD",
      "#6971DD",
      "#FC8686",
      "#F0AE76",
      "#56CD9E",
      "#69B0DD",
      "#6971DD",
      "#FC8686",
      "#F0AE76",
      "#56CD9E",
      "#69B0DD",
      "#6971DD",
      "#FC8686",
      "#F0AE76",
      "#56CD9E",
      "#69B0DD",
      "#6971DD",
      "#FC8686",
      "#F0AE76",
      "#56CD9E",
      "#69B0DD",
      "#6971DD",
      "#FC8686",
      "#F0AE76",
      "#56CD9E",
      "#69B0DD",
      "#6971DD",
      "#FC8686",
      "#F0AE76",
      "#56CD9E",
      "#69B0DD",
      "#6971DD",
    ];
  },
  methods: {
    shortenedBatchName(batchName) {
      const myArray = batchName.split(",");
      const length = myArray.length;
      if (length > 2) {
        return `${batchName.split(",")[0]}...`;
      }
      return batchName;
    },
    formatDate(value) {
      if (value) {
        return moment(String(value)).utcOffset("-00:00").format("DD MMM 'YY");
      }
      return "";
    },
    formatTime1(value) {
      if (value) {
        return moment(String(value)).utcOffset("-00:00").format("hh:mm A");
      }
      return "";
    },
    onClickOutside() {
      if (this.isClassPopupOpen === true) {
        this.isClassPopupOpen = false;
      }
      if (this.showSearchBox === true) {
        this.showSearchBox = false;
      }
      if (this.showSelectBox === true) {
        this.showSelectBox = false;
      }
    },
    selectDate(day) {
      if (day) {
        const dayId = day.id;
        // this.scheduleClassData = this.liveClassdata.filter(
        //   (item) => moment(item.StartTime).utc().format("YYYY-MM-DD") === dayId,
        // );
        Mobileapi.getCalendarData(
          {
            CourseId: this.selectedCourseId,
            BatchId: this.selectedBatchId,
            SearchDate: dayId,
            AffiliationId: Number(this.userDetails.AffiliationId),
            SubjectId: this.selectedSubId,
          },
          (response) => {
            this.upcomingCalendarData = response.data;
          },
        );
      }
    },
    // Start upcoming class
    endTime(startTime, endTime) {
      return new Date(
        new Date(`1970/01/01 ${startTime.substring(11, 16)}`).getTime() + endTime * 60000,
      ).toLocaleTimeString("en-UK", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      });
    },
    startClass(data) {
      // if (data.ZoomStartUrl == null) {
      //   const serverid = data.ServerId > 0 ? data.ServerId : 1;
      //   const url = `http://api.speedslabs.in/api_enc.php?meetingid=${data.Meetingid}&meetingname=${data.Title}&moderatorname=${data.FacultyName}&server=${serverid}&logouturl=https://speedlabsstaging.azurewebsites.net/AffiliationGetLiveClasses.aspx&createandjoin=Start`;
      //   window.open(url, "_blank");
      // } else {
      //   this.isClassPopupOpen = false;
      //   window.open(data.ZoomStartUrl, "_blank");
      // }
      if (data.ZoomStartUrl == null) {
        const serverid = data.ServerId > 0 ? data.ServerId : 1;
        const url = `http://api.speedslabs.in/api_enc.php?meetingid=${data.Meetingid}&meetingname=${data.Title}&moderatorname=${data.FacultyName}&server=${serverid}&logouturl=https://speedlabsstaging.azurewebsites.net/AffiliationGetLiveClasses.aspx&createandjoin=Start`;
        window.open(url, "_blank");
      } else {
        let newUrl = "";
        Mobileapi.getZoomUrl(Number(data.Id), (response) => {
          newUrl = response;
          window.open(newUrl, "_blank");
        });
      }
    },
    getTimeDiff(startDate, duration, DisplayStartTime) {
      let status = "startBtnDeactive";
      const currDate = new Date();
      const minsToAdd = duration;
      const minsToMin = -15;
      const time = DisplayStartTime.substring(11, 16);
      const endTime = new Date(
        new Date(`1970/01/01 ${time}`).getTime() + minsToAdd * 60000,
      ).toLocaleTimeString("en-UK", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
      });
      const start = new Date(
        new Date(`1970/01/01 ${time}`).getTime() + minsToMin * 60000,
      ).toLocaleTimeString("en-UK", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
      });
      const date = moment(currDate).format("DD/MM/YYYY");
      const startDates = moment(startDate).utcOffset("-00:00").format("DD/MM/YYYY");
      const currTime = moment(currDate).format("HH:mm");
      if (startDates === date) {
        if (start <= currTime && currTime <= endTime) {
          status = "";
        } else {
          status = "startBtnDeactive";
        }
      }
      return status;
    },
    // Cancel class
    cancelLiveClass(classId) {
      this.cancelLiveClassId = classId;
      this.CancelClassModal = true;
    },
    cancelLiveClassPopup() {
      this.isClassCancel = true;
      this.cancelClass(this.cancelLiveClassId);
    },
    cancelClass(classId) {
      if (this.isClassCancel) {
        this.$store.dispatch("showLoader", true);
        Mobileapi.cancelOnlineLiveClass(
          classId,
          () => {
            let todayDate = new Date();
            todayDate = moment(`${todayDate}`).utc().format("YYYY-MM-DD");
            Mobileapi.liveclassdata(
              {
                upcoming: 1,
              },
              (response) => {
                this.liveClassdata = response;
                this.upcomingClassData = response.filter(
                  (item) => moment(item.StartTime).utc().format("YYYY-MM-DD") === todayDate,
                );
                this.scheduleClassData = this.upcomingClassData;
                this.CancelClassModal = false;
                this.isClassPopupOpen = false;
                this.$store.dispatch("showLoader", false);
              },
            );
          },
          () => {
            this.$store.dispatch("showLoader", false);
          },
        );
      } else {
        this.isClassCancel = false;
      }
    },
    // Reschedule
    reschedule(Data) {
      const startDate = moment(Data.StartTime).utcOffset("-00:00").format("YYYY-MM-DD");
      this.isPageChange = true;
      this.$store.dispatch("showLoader", true);
      this.$router.push({
        name: "EditLiveClass",
        params: {
          Id: Data.Id,
          OnlineClassId: Data.OnlineClassId,
          BBBMeeting: Data.BBBMeeting,
          Title: Data.Title,
          Course: null,
          FacultyId: Data.FacultyId,
          editBatchId: Data.BatchId,
          subjects: Data.SubjectId,
          StartDate: startDate,
          StartTime: Data.StartTime.substring(11, 16),
          Frequency: Data.ScheduleType,
          Duration: Data.Duration.toString(),
          WeeklyOccurance: Data.WeeklyOccurance,
          EndDate: Data.EndDate,
          ClassType: Data.ClassType,
        },
      });
      this.$store.dispatch("showLoader", false);
      this.isClassPopupOpen = false;
    },
    // Select option
    selectCourse(courseId, CourseName) {
      this.showSortedLeaderboard = false;
      this.recommendationData = [];
      this.leaderBoardData = [];
      this.InstituteTestData = [];
      this.extraClassData = [];
      this.assignmentData = [];
      this.selectedCourseName = CourseName;
      this.selectedCourseId = courseId;
      this.selectedBatchName = "";
      this.selectedBatchId = null;
      this.selectedSubName = "";
      this.selectedSubId = null;
    },
    selectBatch(batchId, batchName, course, firstIndexSub, userClassId) {
      this.selectedBatchId = batchId;
      this.recommendationData = [];
      this.leaderBoardData = [];
      this.InstituteTestData = [];
      this.extraClassData = [];
      this.assignmentData = [];
      this.selectedSubId = null;
      this.selectedSubName = "";
      this.showSortedLeaderboard = false;
      this.selectedBatchName = batchName;
      if (
        (this.selectedCourseName === "" && this.selectedCourseId === null) ||
        this.selectedCourseId !== course.CourseId
      ) {
        this.selectedCourseName = course.CourseName;
        this.selectedCourseId = course.CourseId;
      }
      this.selectedSubId = firstIndexSub[0].SubjectId;
      this.selectedSubName = firstIndexSub[0].SubjectName;
      this.classId = userClassId;
      if (this.classId) {
        // this.classId = classId;
        this.showClassSelectionModal = false;
      } else {
        Mobileapi.getClassforbatch(
          {
            BatchId: this.selectedBatchId,
          },
          (resp) => {
            this.getclass = resp.data;
            if (this.getclass.length === 1) {
              this.classId = this.getclass[0].id || this.getclass[0].Id;
              this.showClassSelectionModal = false;
            } else {
              this.showClassSelectionModal = true;
              // this.selectedClassId = resp.data[0].Id;
            }
          },
        );
      }
      localStorage.removeItem("TeacherDashboardData");
      const TeacherDashboardData = {
        courseId: this.selectedCourseId,
        courseName: this.selectedCourseName,
        batchId: this.selectedBatchId,
        batchName: this.selectedBatchName,
        subjectId: this.selectedSubId,
        subjectname: this.selectedSubName,
        classId: this.classId,
      };
      localStorage.setItem("TeacherDashboardData", JSON.stringify(TeacherDashboardData));
      this.$store.dispatch("showLoader", true);
      Mobileapi.getTeacherDashboardData(
        {
          batchIds: this.selectedBatchId,
        },
        (response) => {
          this.recommendationData = response.data;
          this.leaderBoardData = this.recommendationData.leaderboard.sort(
            (a, b) => Number(b.Attempted) - Number(a.Attempted),
          );
          this.templeaderBoardData = this.leaderBoardData;
          this.InstituteTestData = this.recommendationData.recommendations.InstituteTest
            ? this.recommendationData.recommendations.InstituteTest
            : [];
          this.tempInstituteTestData = this.InstituteTestData;
          this.extraClassData = this.recommendationData.recommendations.ExtraClass
            ? this.recommendationData.recommendations.ExtraClass
            : [];
          this.tempextraClassData = this.extraClassData;
          this.assignmentData = this.recommendationData.recommendations.Assignment
            ? this.recommendationData.recommendations.Assignment
            : [];
          this.tempassignmentData = this.assignmentData;
          this.showSortedLeaderboard = false;
          this.$store.dispatch("showLoader", false);
          this.extraClassData = this.tempextraClassData.filter(
            (item) => item.subjectId === this.selectedSubId,
          );
          this.assignmentData = this.tempassignmentData.filter(
            (item) => item.subjectId === this.selectedSubId,
          );
          this.InstituteTestData = this.tempInstituteTestData.filter(
            (item) => item.subjectId === this.selectedSubId,
          );
          this.LeaderAttempted = `Attempted_${this.selectedSubId}`;
          this.LeaderAccuracy = `Accuracy_${this.selectedSubId}`;
          this.leaderBoardData = this.templeaderBoardData.sort(
            (a, b) => b[this.LeaderAttempted] - a[this.LeaderAttempted],
          );
          Mobileapi.getCalendarData(
            {
              CourseId: this.selectedCourseId,
              BatchId: this.selectedBatchId,
              SearchDate: this.selectedDate,
              AffiliationId: Number(this.userDetails.AffiliationId),
              SubjectId: this.selectedSubId,
            },
            (resp) => {
              this.upcomingCalendarData = resp.data;
              this.$store.dispatch("showLoader", false);
            },
          );
          this.showSelectBox = false;
          this.showSearchBox = false;
          this.searchValue = "";
        },
        () => {
          this.$store.dispatch("showLoader", false);
        },
      );
    },
    selectSubject(subId, subName, batchDetail, course) {
      if (
        (this.selectedCourseName === "" && this.selectedCourseId === null) ||
        this.selectedCourseId !== course.CourseId
      ) {
        this.selectedCourseName = course.CourseName;
        this.selectedCourseId = course.CourseId;
        this.recommendationData = [];
      }
      if (
        (this.selectedBatchName === "" && this.selectedBatchId === null) ||
        this.selectedBatchId !== batchDetail.CenterId
      ) {
        this.selectedBatchName = batchDetail.Name;
        this.selectedBatchId = batchDetail.CenterId;
        this.recommendationData = [];
        this.selectedSubId = null;
        this.selectedSubName = "";
      }
      this.selectedSubId = subId;
      this.selectedSubName = subName;
      const classId = batchDetail.ClassId;
      if (classId) {
        this.classId = classId;
        this.showClassSelectionModal = false;
      } else {
        Mobileapi.getClassforbatch(
          {
            BatchId: this.selectedBatchId,
          },
          (resp) => {
            this.getclass = resp.data;
            this.showClassSelectionModal = true;
            // this.selectedClassId = resp.data[0].Id;
          },
        );
      }
      localStorage.removeItem("TeacherDashboardData");
      const TeacherDashboardData = {
        courseId: this.selectedCourseId,
        courseName: this.selectedCourseName,
        batchId: this.selectedBatchId,
        batchName: this.selectedBatchName,
        subjectId: this.selectedSubId,
        subjectname: this.selectedSubName,
        classId: this.classId,
      };
      localStorage.setItem("TeacherDashboardData", JSON.stringify(TeacherDashboardData));

      if (this.recommendationData.length === 0) {
        if (this.selectedBatchId && this.selectedCourseId) {
          this.$store.dispatch("showLoader", true);
          Mobileapi.getTeacherDashboardData(
            {
              batchIds: this.selectedBatchId,
            },
            (response) => {
              this.recommendationData = response.data;
              this.leaderBoardData = this.recommendationData.leaderboard.sort(
                (a, b) => Number(b.Attempted) - Number(a.Attempted),
              );
              this.templeaderBoardData = this.leaderBoardData;
              this.InstituteTestData = this.recommendationData.recommendations.InstituteTest
                ? this.recommendationData.recommendations.InstituteTest
                : [];
              this.tempInstituteTestData = this.InstituteTestData;
              this.extraClassData = this.recommendationData.recommendations.ExtraClass
                ? this.recommendationData.recommendations.ExtraClass
                : [];
              this.tempextraClassData = this.extraClassData;
              this.assignmentData = this.recommendationData.recommendations.Assignment
                ? this.recommendationData.recommendations.Assignment
                : [];
              this.tempassignmentData = this.assignmentData;
              // this.selectedSubId = subId;
              // this.selectedSubName = subName;
              this.extraClassData = this.tempextraClassData.filter(
                (item) => item.subjectId === this.selectedSubId,
              );
              this.assignmentData = this.tempassignmentData.filter(
                (item) => item.subjectId === this.selectedSubId,
              );
              this.InstituteTestData = this.tempInstituteTestData.filter(
                (item) => item.subjectId === this.selectedSubId,
              );
              this.LeaderAttempted = `Attempted_${this.selectedSubId}`;
              this.LeaderAccuracy = `Accuracy_${this.selectedSubId}`;
              this.leaderBoardData = this.templeaderBoardData.sort(
                (a, b) => b[this.LeaderAttempted] - a[this.LeaderAttempted],
              );
              this.$store.dispatch("showLoader", true);
              Mobileapi.getCalendarData(
                {
                  CourseId: this.selectedCourseId,
                  BatchId: this.selectedBatchId,
                  SearchDate: this.selectedDate,
                  AffiliationId: Number(this.userDetails.AffiliationId),
                  SubjectId: this.selectedSubId,
                },
                (resp) => {
                  this.upcomingCalendarData = resp.data;
                  this.$store.dispatch("showLoader", false);
                },
              );
              this.showSortedLeaderboard = true;
              this.showSelectBox = false;
              this.showSearchBox = false;
              this.searchValue = "";
            },
            () => {
              this.$store.dispatch("showLoader", false);
            },
          );
        }
      } else {
        this.selectedSubId = subId;
        this.selectedSubName = subName;
        this.extraClassData = this.tempextraClassData.filter((item) => item.subjectId === subId);
        this.assignmentData = this.tempassignmentData.filter((item) => item.subjectId === subId);
        this.InstituteTestData = this.tempInstituteTestData.filter(
          (item) => item.subjectId === subId,
        );
        this.LeaderAttempted = `Attempted_${this.selectedSubId}`;
        this.LeaderAccuracy = `Accuracy_${this.selectedSubId}`;
        this.leaderBoardData = this.templeaderBoardData.sort(
          (a, b) => b[this.LeaderAttempted] - a[this.LeaderAttempted],
        );
        Mobileapi.getCalendarData(
          {
            CourseId: this.selectedCourseId,
            BatchId: this.selectedBatchId,
            SearchDate: this.selectedDate,
            AffiliationId: Number(this.userDetails.AffiliationId),
            SubjectId: this.selectedSubId,
          },
          (resp) => {
            this.upcomingCalendarData = resp.data;
            this.$store.dispatch("showLoader", false);
          },
        );
        this.showSortedLeaderboard = true;
        this.showSelectBox = false;
        this.showSearchBox = false;
        this.searchValue = "";
      }
    },
    onClassChange(classId) {
      this.classId = classId;
      const TeacherDashboardData = JSON.parse(localStorage.getItem("TeacherDashboardData"));
      TeacherDashboardData.classId = this.classId;
      localStorage.setItem("TeacherDashboardData", JSON.stringify(TeacherDashboardData));
      this.showClassSelectionModal = false;
    },
    goBatch() {
      this.isPageChange = true;
      this.$router.push({
        name: "Batch",
      });
    },
    goStudent() {
      this.isPageChange = true;
      this.$router.push({
        name: "Student",
      });
    },
    goUser() {
      this.isPageChange = true;
      this.$router.push({
        name: "RoleWiseUserListing",
      });
    },
    /* Report Navigation */
    // Batch Wise
    viewEffortAnalysis() {
      this.isPageChange = true;
      this.$router.push({
        name: "EffortAnalysisNew",
        params: {
          teacherDashCourseId: this.selectedCourseId,
          teacherDashBatchId: Number(this.selectedBatchId),
          teacherDashClassId: this.classId,
          teacherDashSubId: this.selectedSubId,
        },
      });
    },

    goBatchReport() {
      this.isPageChange = true;
      this.$router.push({
        name: "BatchReport",
        params: {
          teacherDashCourseId: this.selectedCourseId,
          teacherDashBatchId: this.selectedBatchId,
          teacherDashSubId: this.selectedSubId,
          teacherDashBatchName: this.selectedBatchName,
          teacherDashSubName: this.selectedSubName,
          teacherDashAffId: Number(this.userDetails.AffiliationId),
        },
      });
    },

    // Subject Wise
    viewSubCoverage() {
      this.isPageChange = true;
      this.$router.push({
        name: "PracticeCoverage",
        params: {
          teacherDashCourseId: this.selectedCourseId,
          teacherDashBatchId: this.selectedBatchId,
          teacherDashAffId: Number(this.userDetails.AffiliationId),
          teacherDashSubId: this.selectedSubId,
        },
      });
    },
    // Test wise
    viewTestAnalysis() {
      this.isPageChange = true;
      this.$router.push({
        name: "ManageInstituteTest",
        params: {
          teacherDashCourseId: this.selectedCourseId,
          teacherDashBatchId: this.selectedBatchId,
          teacherDashAffId: Number(this.userDetails.AffiliationId),
        },
      });
    },
    // onClickOutsideCalendar() {
    //   Mobileapi.getCalendarData({
    //     CourseId: this.selectedCourseId,
    //     BatchId: this.selectedBatchId,
    //     SearchDate: moment(`${new Date()}`).utc().format("YYYY-MM-DD"),
    //     AffiliationId: Number(this.userDetails.AffiliationId),
    //     SubjectId: this.selectedSubId,
    //   },
    //   (response) => {
    //     this.upcomingCalendarData = response.data;
    //   });
    // },
    // Test
    createTest(testData) {
      Mobileapi.getTopicId(
        {
          SubjectId: this.selectedSubId,
          ChapterId: testData.chapterId,
        },
        (topicResponse) => {
          this.isPageChange = true;
          this.$router.push({
            name: "CreateInstituteTest",
            params: {
              courseIdProps: this.selectedCourseId,
              subIdProps: this.selectedSubId,
              chapterIdProps: testData.chapterId,
              topicIdProps: topicResponse.data.TopicId,
              classIdProps: this.classId,
              batchIdProps: this.selectedBatchId,
            },
          });
        },
      );
    },
    CreateNewTest() {
      this.isPageChange = true;
      this.$router.push({
        name: "CreateInstituteTest",
        params: {
          courseIdProps: this.selectedCourseId,
          subIdProps: this.selectedSubId,
          classIdProps: this.classId,
          batchIdProps: this.selectedBatchId,
        },
      });
    },
    // Extra Class
    scheduleClass(data) {
      if (this.selectedBatchId && this.selectedCourseId) {
        this.isPageChange = true;
        console.log(data);
        this.$router.push({
          name: "CreateLiveClassV1",
          params: { editBatchId: this.selectedBatchId, subjects: data.subjectId },
        });
      } else {
        this.isPageChange = true;
        this.$router.push({ name: "CreateLiveClassV1" });
      }
    },
    scheduleNewClass() {
      this.isPageChange = true;
      this.$router.push({ name: "CreateLiveClassV1" });
    },
    // Assignment
    // Assignment
    createAssignment(assiData) {
      this.isPageChange = true;
      if (this.selectedBatchId && this.selectedCourseId) {
        this.$router.push({
          name: "CreateAssignment",
          params: {
            teacherDashAffilId: Number(this.userDetails.AffiliationId),
            teacherDashAssigData: assiData,
            teacherDashCourseId: this.selectedCourseId,
            teacherDashSubId: this.selectedSubId,
            teacherDashBatchId: this.selectedBatchId,
            teacherDashClassId: this.classId,
            teacherDashCourseChapterId: assiData.courseChapterId,
          },
        });
      }
    },
    CreateNewAssignment() {
      this.isPageChange = true;
      this.$router.push({
        name: "CreateAssignment",
        // params: {
        //   teacherDashAffilId: 0,
        //   teacherDashAssigData: null,
        //   teacherDashCourseId: 0,
        //   teacherDashBatchId: 0,
        // },
      });
    },
  },
  components: {
    // FunctionalCalendar,
    Modal,
    Modal2,
    ProfileAvatar,
    ClassCard,
  },
  computed: {
    filterBatch() {
      return [...new Map(this.SelectedBatchList.map((item) => [item.BatchName, item])).values()];
    },
    filterSubject() {
      return [...new Map(this.courseSubjectList.map((item) => [item.SubjectName, item])).values()];
    },
    filterCourse() {
      return [...new Map(this.SelectedCourseList.map((item) => [item.courseId, item])).values()];
    },
    filteredList() {
      if (this.searchValue !== "" && this.searchValue) {
        const filteredCourse = this.selectOptionData.filter((item) =>
          item.CourseName.toLowerCase().includes(this.searchValue.toLowerCase()),
        );
        const filteredBatch = this.selectOptionData.filter((item) =>
          item.BatchDetails.some((item3) =>
            item3.Name.toLowerCase().includes(this.searchValue.toLowerCase()),
          ),
        );
        const filteredSubject = this.selectOptionData.filter((item) =>
          item.BatchDetails.some((item4) =>
            item4.subjectDetails[0].some((item5) =>
              item5.SubjectName.toLowerCase().includes(this.searchValue.toLowerCase()),
            ),
          ),
        );
        // const filteredSubject = this.selectOptionData.filter((item) => JSON.stringify(item).toLowerCase().includes(this.searchValue.toLowerCase()));
        if (filteredCourse.length > 0) {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.searchedData = filteredCourse;
        } else if (filteredBatch.length > 0) {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.searchedData = filteredBatch;
        } else if (filteredSubject.length > 0) {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.searchedData = filteredSubject;
        } else {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.searchedData = "";
        }
      } else {
        return "";
      }
      return this.searchedData;
    },
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap");

.teacher-dashboard {
  font-family: "Open Sans" !important;
}

/* Custom CSS */
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul {
  -webkit-user-select: none;
  /* Safari */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* IE10+/Edge */
  user-select: none;
  /*Standard */
  margin: 0;
}

@font-face {
  font-family: PackMan;
  src: url(../../font/pac-font.ttf);
}

* {
  box-sizing: border-box;
}

.dark-text {
  color: #333333 !important;
}

.black-text2 {
  color: #242c3c;
}

.black-text {
  color: #000000 !important;
}

.skyblue-text {
  color: #3751ff !important;
}

.purple-text {
  color: #642c90 !important;
}

.orange-text {
  color: #f59e00 !important;
}

.light-gray-text {
  color: #666666;
}

.white-bgc {
  background: #ffffff;
}

.orange-bgc {
  background: linear-gradient(90.2deg, #ff9421 0.16%, #fbad26 98.41%);
}

.lightPink-bgc {
  background: #ffecdb;
}

.lightGreen-bgc {
  background: #ebffe7;
}

.test-bgc {
  background: linear-gradient(98.34deg, #e9ccff 11.31%, #f4e6ff 88.13%);
}

.purple-bgc {
  background: #642c90;
}

.card-border {
  border: 1px solid #e3e3e3;
}

.card-shadow {
  box-shadow: 4px 4px 10px rgba(100, 44, 144, 0.06);
}

.card-radius {
  border-radius: 5px;
}

.relative {
  position: relative !important;
}

.absolute {
  position: absolute !important;
}

.block {
  display: block;
}

.flex {
  display: flex !important;
}

.flex-between {
  justify-content: space-between;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-column {
  flex-direction: column;
}

.flex-center {
  justify-content: center;
}

.items-center {
  align-items: center;
}

.items-baseline {
  align-items: baseline;
}

.items-end {
  align-items: flex-end;
}

.max-content {
  width: max-content;
}

.fs-24 {
  font-size: 24px !important;
}

.fs-18 {
  font-size: 18px !important;
}

.fs-15 {
  font-size: 15px !important;
}

.fs-14 {
  font-size: 14px !important;
}

.fs-9 {
  font-size: 9px !important;
}

.fs-10 {
  font-size: 10px !important;
}

.fs-9 {
  font-size: 9px !important;
}

.fs-11 {
  font-size: 10px !important;
}

.fs-12 {
  font-size: 12px !important;
}

.fw-700 {
  font-weight: 700 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.fw-400 {
  font-weight: 400 !important;
}

.m-0 {
  margin: 0;
}

.mt-31 {
  margin-top: 31px;
}

.mb-24 {
  margin-bottom: 24px;
}

.w-105 {
  width: 105px;
}

.w-69 {
  width: 69px;
}

.w-75 {
  width: 75px;
}

.p-15 {
  padding: 15px;
}

.pointer {
  cursor: pointer;
}

.uppercase {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize;
}

.hidden {
  opacity: 0;
  visibility: hidden;
}

.scroll-horizontal {
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
}

.scroll-horizontal::-webkit-scrollbar {
  height: 0px;
}

.scroll-horizontal::-webkit-scrollbar-track {
  background-color: #ececec;
  border-radius: 5px;
}

.scroll-horizontal::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 5px;
}

.scroll-verticle {
  overflow-y: scroll;
  overflow-x: hidden;
  white-space: nowrap;
}

.scroll-verticle::-webkit-scrollbar {
  width: 0px;
}

.select-box.scroll-verticle::-webkit-scrollbar {
  width: 0px;
}

.scroll-verticle::-webkit-scrollbar-track {
  background-color: #ececec;
  border-radius: 5px;
}

.scroll-verticle::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 5px;
}

.scroll-verticle {
  overflow-y: scroll;
  overflow-x: hidden;
  white-space: nowrap;
}

.scroll-verticle::-webkit-scrollbar {
  width: 0px;
}

.scroll-verticle::-webkit-scrollbar-track {
  background-color: #ececec;
  border-radius: 5px;
}

.scroll-verticle::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 5px;
}

.yellow-btn {
  background: linear-gradient(90.2deg, #ff9421 0.16%, #fbad26 98.41%);
  border-radius: 3px;
  border: none;
  outline: none;
  height: 21px;
  font-weight: 600;
  font-size: 12px;
  line-height: 21px;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
  z-index: 20;
}

.reports .yellow-btn {
  line-height: 22px;
}

/* Main CSS */
.teacher-dashboard {
  padding-bottom: 15px;
}

.left-section {
  width: 355px;
  padding: 18px;
  margin-right: 20px;
  /*
      padding: 0;
      padding-right: 20px;*/
}

.class-card {
  background: #ffffff;
  border: 1px solid #e3e3e3;
  border-radius: 5px;
  max-width: 333px;
  height: 53px;
  margin-bottom: 10px;
  padding: 10px;
}

.class-card:nth-child(3n-2)::before {
  position: absolute;
  content: "";
  top: 2px;
  left: 0;
  background: #f59e00;
  border-radius: 0px 4px 4px 0px;
  width: 4px;
  height: 46px;
}

.class-card:nth-child(3n-1)::before {
  position: absolute;
  content: "";
  top: 2px;
  left: 0;
  border-radius: 0px 4px 4px 0px;
  width: 4px;
  height: 46px;
  background: #48b8bf;
}

.class-card:nth-child(3n + 0)::before {
  position: absolute;
  content: "";
  top: 2px;
  left: 0;
  border-radius: 0px 4px 4px 0px;
  width: 4px;
  height: 46px;
  background: #a979e6;
}

.class-card .popup {
  position: absolute;
  width: 85px;
  height: 58px;
  right: 25px;
  top: 1px;
  background: #ffffff;
  border: 1px solid #e3e3e3;
  box-shadow: 4px 4px 10px rgb(100 44 144 / 6%);
  border-radius: 5px;
  justify-content: center;
  align-items: flex-start;
  padding-left: 13px;
  z-index: 10;
}

.class-card .popup::after {
  position: absolute;
  content: "";
  width: 7px;
  height: 7px;
  border-top: 1px solid #e3e3e3;
  border-right: 1px solid #e3e3e3;
  border-bottom: 0px solid #e3e3e3;
  border-left: 0px solid #e3e3e3;
  top: 21px;
  right: -4px;
  margin-left: -4px;
  -webkit-backdrop-filter: #fff;
  backdrop-filter: #fff;
  transform: rotate(45deg);
  background: #fff;
}

.startBtnDeactive {
  pointer-events: none;
  color: #80808078 !important;
}

.startBtnActive {
  color: #333333 !important;
}

/* Calendar */
.calender-body {
  background: #ffffff;
  border: 1px solid #e3e3e3;
  border-radius: 5px;
  /*margin-bottom: 10px;*/
}

.calender-box {
  position: relative;
}

.calender-box::after {
  position: absolute;
  content: "";
  bottom: 15px;
  left: 16px;
  width: 90%;
  height: 1px;
  background: #f6f6f6;
}

.events {
  margin: 15px 10px;
}

.events .yellow-btn {
  opacity: 0;
  visibility: hidden;
}

.events:hover .yellow-btn {
  opacity: 1;
  visibility: visible;
}

.events:nth-child(1) {
  border-top: 1px solid #f7f7f7 !important;
  padding-top: 10px !important;
}

/*.events::before {
      position: absolute;
      content: "";
      width: 3px;
      height: 27px;
      left: 0;
      top: 3px;
      background: #3751FF;
      border-radius: 2px;
  }*/

.events:nth-child(1)::before {
  top: 14px !important;
}

.vc-container {
  border: none !important;
}

/*
  .middle-section {
      max-width: 570px;
      min-width: 220px;
      width: 570px;
      position: relative;
      margin: 0 15px 0 15px;
  }*/
.main-container {
  max-width: 900px;
  width: 100%;
  position: relative;
}

.top-box {
  height: 472px;
  width: 100%;
  position: relative;
}

.middle-section {
  /* max-width: 570px; */
  width: 100%;
  height: 100%;
  margin-right: 15px;
  position: relative;
}

.middle-section .head-box {
  width: 80px;
  height: 28px;
  left: 507px;
  top: 99px;
  background: #ffffff;
  border: 1px solid #e3e3e3;
  box-shadow: 4px 4px 10px rgba(100, 44, 144, 0.06);
  border-radius: 5px;
  padding: 5px 15px;
  font-weight: 400;
  margin-right: 14px;
  box-sizing: border-box;
}

.middle-section .head-box i {
  font-size: 16px;
  margin-left: 8px;
  color: #000000;
}

.middle-section #search,
.middle-section #search2 {
  margin: 0;
  margin-top: 2px;
  width: 89px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  background-repeat: no-repeat;
  text-indent: 28px;
  background-position: 8px 5px;
  padding: 5px;
  background-image: url("../../assets/search\ 1.svg");
}

.middle-section #search2 {
  background-position: 15px 9px !important;
  padding: 17px 15px !important;
}

.middle-section #search2::-webkit-input-placeholder,
.middle-section #search::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #333333;
  opacity: 0.7;
}

.middle-section #search2::-moz-placeholder,
.middle-section #search::-moz-placeholder {
  /* Firefox 19+ */
  color: #333333;
  opacity: 0.7;
}

.middle-section #search2:-ms-input-placeholder,
.middle-section #search:-ms-input-placeholder {
  /* IE 10+ */
  color: #333333;
  opacity: 0.7;
}

.middle-section #search2:-moz-placeholder,
.middle-section #search:-moz-placeholder {
  /* Firefox 18- */
  color: #333333;
  opacity: 0.7;
}

.middle-section #search2:not(.browser-default):focus:not([readonly]),
.middle-section #search:not(.browser-default):focus:not([readonly]) {
  border: 1px solid #e3e3e3;
  box-shadow: 4px 4px 10px rgba(100, 44, 144, 0.06);
}

.leader-sec {
  width: 216px;
  height: 100%;
}

.extra-classes {
  padding: 16px 14px;
  border: 1px solid #e3e3e3;
  box-shadow: 4px 4px 10px rgba(100, 44, 144, 0.06);
  border-radius: 5px;
  width: 277px;
}

.extra-classes:nth-child(1) {
  margin-right: 7px;
}

.extra-classes:nth-child(2) {
  margin-left: 7px;
}

.extra-class-content {
  width: 246px;
  height: 94px;
  border: 1px solid #e3e3e3;
  border-radius: 5px;
  margin-bottom: 15px;
  padding: 15px;
  transition: 0.3s all ease;
}

.extra-class-content:last-child {
  margin-bottom: 0;
}

.extra-class-content .yellow-btn {
  opacity: 0;
  visibility: hidden;
}

.extra-class-header {
  position: relative;
}

.extra-class-header::before {
  position: absolute;
  content: "";
  top: -2px;
  left: -15px;
  background: transparent;
  border-radius: 0px 2px 2px 0px;
  width: 3px;
  height: 20px;
  transition: 0.3s all ease;
}

.extra-class-content:hover {
  border: 1px solid #333333;
}

.extra-class-content:hover .yellow-btn {
  opacity: 1;
  visibility: visible;
}

.extra-class-content:hover .extra-class-header::before {
  background: #3751ff;
}

.extra-class-content {
  line-height: 19px;
}

/*Report */
.reports {
  position: relative;
  width: 100%;
  /*
      max-height: 436px;
      height: 100%;*/
  height: 436px;
  border-radius: 5px;
  margin-top: 8px;
  padding-bottom: 32px;
}

.reports::after {
  position: absolute;
  content: "";
  top: 69px;
  left: 0;
  width: 100%;
  height: 1px;
  background: #e3e3e3;
}

.reports .report-link .material-icons {
  position: relative;
  bottom: -3px;
}

.report-link li {
  margin-right: 55px;
  cursor: pointer;
  /*border-bottom: 2px solid transparent;
      border-radius: 2px;*/
  position: relative;
  opacity: 0.7;
}

.report-link li:after {
  position: absolute;
  content: "";
  width: 100%;
  height: 2px;
  background: transparent;
  border-radius: 2px 2px 0px 0px;
  left: 0;
  top: 18px;
}

.reports .report-link li:last-child {
  margin-right: 0;
}

.reports .report-link li.active {
  color: #3751ff !important;
  z-index: 1;
  opacity: 1;
}

.reports .report-link li.active:after {
  background: #3751ff;
}

.report-contents {
  position: relative;
  top: 15px;
  display: grid !important;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 15px;
}

.report-card .yellow-btn {
  opacity: 0;
}

.gray-img {
  display: block;
  visibility: visible;
}

.report-contents .report-card {
  /* max-width: 262px; */
  /*height: 144px;*/
  background: #ffffff;
  border: 1px solid #e3e3e3;
  border-radius: 5px;
  padding: 15px;
}

.report-card h3 {
  position: relative;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  color: #333333;
}

.report-card h3::before {
  position: absolute;
  content: "";
  top: -2px;
  left: -15px;
  background: transparent;
  border-radius: 0px 2px 2px 0px;
  width: 3px;
  height: 20px;
}

.report-card ul {
  margin-bottom: 10px;
  margin-top: 3px;
}

.report-card li:first-child {
  list-style-type: none;
}

.report-card li {
  margin-right: 15px;
  font-weight: 600;
  font-size: 10px;
  /*line-height: 10px;*/
  color: #333333;
  text-transform: uppercase;
  position: relative;
}

.report-card li:nth-child(2)::before,
.report-card li:nth-child(3)::before {
  position: absolute;
  content: "";
  width: 3px;
  height: 3px;
  background: #333333;
  bottom: 6px;
  left: -9px;
  border-radius: 30px;
}

.report-card li:nth-child(3)::before {
  width: 3px !important;
}

.report-card:hover .yellow-btn {
  opacity: 1;
}

.batchWise .report-card:nth-child(1) h3 {
  color: #d45947 !important;
}

.batchWise .report-card:nth-child(2) h3 {
  color: #29a481 !important;
}

.batchWise .report-card:nth-child(3) h3 {
  color: #c18813 !important;
}

.batchWise .report-card:nth-child(4) h3 {
  color: #5766cf !important;
}

.batchWise .report-card:nth-child(1) {
  background: #fff8f7 !important;
  border: 1px solid #f6baad66 !important;
}

.batchWise .report-card:nth-child(1):hover {
  border-color: #f6baad !important;
}

.batchWise .report-card:nth-child(1):hover h3::before {
  background: #d45947 !important;
}

.batchWise .report-card:nth-child(2) {
  background: #effffb !important;
  border: 1px solid #dfefeb !important;
}

.batchWise .report-card:nth-child(2):hover {
  border-color: #a4dbcd !important;
}

.batchWise .report-card:nth-child(2):hover h3::before {
  background: #29a481 !important;
}

.batchWise .report-card:nth-child(3) {
  background: #fffbf2 !important;
  border: 1px solid #f1ebde !important;
}

.batchWise .report-card:nth-child(3):hover {
  border-color: #ebd096 !important;
}

.batchWise .report-card:nth-child(3):hover h3::before {
  background: #c18813 !important;
}

.batchWise .report-card:nth-child(4) {
  background: #f5f6ff !important;
  border: 1px solid #e9eaf3 !important;
}

.batchWise .report-card:nth-child(4):hover {
  border-color: #b7bce7 !important;
}

.batchWise .report-card:nth-child(4):hover h3::before {
  background: #5766cf !important;
}

.subjectWise .report-card:nth-child(1) h3 {
  color: #7c4ace !important;
}

.subjectWise .report-card:nth-child(1) {
  background: #f8f4ff;
  border: 1px solid #dfd9e8;
}

.subjectWise .report-card:nth-child(1):hover {
  border-color: #b8a0dd !important;
}

.subjectWise .report-card:nth-child(1):hover h3::before {
  background: #7c4ace !important;
}

.subjectWise .report-card:nth-child(2) h3 {
  color: #db6d6d !important;
}

.subjectWise .report-card:nth-child(2):hover h3::before {
  background: #db6d6d !important;
}

.subjectWise .report-card:nth-child(2) {
  background: #fff2f2;
  border: 1px solid #eedfdf;
}

.subjectWise .report-card:nth-child(2):hover {
  border-color: #e3b7b7 !important;
}

.Test .report-card:nth-child(1) h3 {
  color: #229ada !important;
}

.Test .report-card:nth-child(1) {
  background: #eef9ff;
  border: 1px solid #e4edf1;
}

.Test .report-card:nth-child(1):hover {
  border-color: #b2cdd9 !important;
}

.Test .report-card:nth-child(1):hover h3::before {
  background: #229ada !important;
}

.select-box {
  width: 100%;
  max-height: 437px;
  position: absolute;
  top: 30px;
  z-index: 200;
  margin-top: 8px;
  background: #ffffff;
  border: 1px solid #e3e3e3;
  box-shadow: 4px 24px 40px rgb(117 117 117 / 16%);
  border-radius: 5px;
  overflow-x: hidden;
  overflow-y: scroll;
  /* padding: 22px 30px; */
  padding: 0 30px 0 0;
}

.select-box .select-content {
  display: flex;
  align-items: flex-start;
  position: relative;
  margin-bottom: 0px;
}

.select-box .select-content:last-child {
  margin-bottom: 0;
}

.select-box .select-content h3 {
  /*cursor: pointer;
      text-transform: uppercase;
      font-weight: 700;
      font-size: 20px;
      line-height: 20px;
      color: #F59E00;
      width: 101px;
      padding-top: 20px;*/
  cursor: pointer;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 20px;
  line-height: 20px;
  /*color: #F59E00;*/
  width: 141px !important;
  width: max-content;
  background: transparent;
  max-height: 192px;
  padding: 0px 81px 0 30px;
  padding-top: 25px;
  position: relative;
  left: 0;
}

/*
  .select-box .select-content:nth-child(3n-2) h3.active {
      background: #f59e0014;
  }

  .select-box .select-content:nth-child(3n-1) h3.active {
      background: #E9FBFC;
  }

  .select-box .select-content:nth-child(3n+0) h3.active {
      background: #a979e62e;
  }*/
.select-box .select-content h3.active {
  pointer-events: none;
}

.select-box .select-content:nth-child(3n-2) h3 {
  color: #f59e00;
}

.select-box .select-content:nth-child(3n-1) h3 {
  color: #0ba3ac;
}

.select-box .select-content:nth-child(3n + 0) h3 {
  color: #a979e6;
}

.select-box .select-content:nth-child(3n-2) h4:hover,
.select-box .select-content:nth-child(3n-2) h5:hover {
  color: #f59e00;
  opacity: 1;
}

.select-box .select-content:nth-child(3n-1) h4:hover,
.select-box .select-content:nth-child(3n-1) h5:hover {
  color: #0ba3ac;
  opacity: 1;
}

.select-box .select-content:nth-child(3n + 0) h4:hover,
.select-box .select-content:nth-child(3n + 0) h5:hover {
  color: #8761b8;
  opacity: 1;
}

/*.select-box .select-content:last-child h3 {
      height: 207px;
  }*/
.select-content .right-content h4 {
  color: #333333;
  opacity: 0.7;
}

.select-content .right-content {
  display: flex;
  align-items: baseline;
  padding: 12px 0;
}

.select-content .right-content h4 {
  cursor: pointer;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  width: 165px;
  /*color: #845602;*/
}

.select-content .right-content .right-subject-box {
  font-weight: 400;
  line-height: 16px;
  color: #333333;
  opacity: 0.7;
  display: flex;
  /* height: 16px; */
  /* max-width: 395px; */
  margin-top: 4px;
  /* margin-left: 47px; */
}

.select-content .subject-items {
  /*max-width: 660px; */
  position: relative;
  right: -17px;
  padding: 10px 0;
  padding-bottom: 6px;
}

/*.select-content:first-child .subject-items, .select-content:first-child h3 {
  padding-top: 24px;
  }*/
.select-content:last-child .subject-items {
  padding-bottom: 21px;
}

.select-content .subject-items::before {
  position: absolute;
  content: "";
  width: 1px;
  height: 100%;
  background: #e3e3e3;
  top: 0;
  left: -17px;
}

.select-content:nth-child(3n-2) .subject-items .right-content h4.active,
.select-content:nth-child(3n-2) .subject-items .right-content h5.active {
  color: #f59e00;
  opacity: 1;
  pointer-events: none;
  font-weight: 700;
}

.select-content:nth-child(3n-2) .subject-items.active::before {
  position: absolute;
  content: "";
  width: 3px;
  height: 100%;
  background: #f59e00;
  border-radius: 0px 2px 2px 0px;
  top: 0;
  left: -17px;
}

.select-content:nth-child(3n-1) .subject-items .right-content h4.active {
  color: #0ba3ac;
  opacity: 1;
  pointer-events: none;
  font-weight: 700;
}

.select-content:nth-child(3n-1) .subject-items.active::before {
  position: absolute;
  content: "";
  width: 3px;
  height: 100%;
  background: #0ba3ac;
  border-radius: 0px 2px 2px 0px;
  top: 0;
  left: -17px;
}

.select-content:nth-child(3n-0) .subject-items .right-content h4.active {
  color: #8761b8;
  opacity: 1;
  pointer-events: none;
  font-weight: 700;
}

.select-content:nth-child(3n + 0) .subject-items.active::before {
  position: absolute;
  content: "";
  width: 3px;
  height: 100%;
  background: #a979e6;
  border-radius: 0px 2px 2px 0px;
  top: 0;
  left: -17px;
}

.right-subject-box .right-subject {
  font-size: 12px;
  margin-right: 25px;
  cursor: pointer;
  margin-bottom: 5px;
  /*    font-size: 12px;
      margin-right: 30px;*/
}

.select-content:nth-child(3n-2) .right-content .right-subject-box .right-subject.active {
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  color: #f59e00;
}

.select-content:nth-child(3n-1) .right-content .right-subject-box .right-subject.active {
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  color: #087980;
}

.select-content:nth-child(3n + 0) .right-content .right-subject-box .right-subject.active {
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  color: #8239e2;
}

.search-content {
  /*max-width: 538px;*/
  max-width: 100%;
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: 150;
  top: 1px;
  background: #ffffff;
  border: 1px solid #e3e3e3;
  box-shadow: 4px 24px 40px rgb(117 117 117 / 16%);
  border-radius: 5px;
  overflow-x: hidden;
  overflow-y: scroll;
}

.search-content .head-box {
  width: 100% !important;
  border-radius: 0;
  border: none;
  border-bottom: 1px solid #e3e3e3;
  box-shadow: none;
}

.search-content #search:not(.browser-default):focus:not([readonly]) {
  border: none;
  border-bottom: 1px solid #e3e3e3;
  box-shadow: none;
}

.search-content #search2:not(.browser-default):focus:not([readonly]) {
  border: none;
  border-bottom: 1px solid #e3e3e3;
  box-shadow: none;
}

.search-item {
  padding: 15px 44px;
  padding-top: 0;
  position: absolute;
  height: 100%;
  width: 100%;
}

.search-item .search-item-content {
  padding: 15px 0;
  border-bottom: 1px solid #e3e3e378;
}

.search-item .search-item-content:last-child {
  border: none;
}

.search-item .search-item-content h2 {
  font-weight: 700;
  font-size: 10px;
  line-height: 14px;
  color: #000000;
  text-transform: uppercase;
  margin-bottom: 10px;
  cursor: pointer;
  width: max-content;
}

.search-item .search-item-content h2.active {
  font-weight: 700;
  font-size: 10px;
  line-height: 14px;
  color: #3751ff;
}

.search-item .search-item-content ul {
  font-weight: 400;
  /*line-height: 19px; */
  color: #333333;
  margin-bottom: 10px;
}

.search-item .search-item-content ul li {
  font-size: 14px;
  opacity: 0.7;
  margin-bottom: 8px;
}

.search-item .search-item-content .subject-item {
  max-width: 340px;
}

.search-item .search-item-content .subject {
  margin-left: 8px;
  cursor: pointer;
}

.search-item .search-item-content ul li span {
  cursor: pointer;
  /*width: 108px;*/
}

.search-item .search-item-content ul li span:first-child {
  display: inline-block;
  position: relative;
  margin-right: 7px;
}

.search-item .search-item-content ul li span:last-child {
  margin-left: 7px;
}

.search-item .search-item-content ul li .batch::before {
  position: absolute;
  content: "";
  right: -10px;
  width: 4px;
  height: 4px;
  border-radius: 30px;
  border: none;
  top: 50%;
  background: #333333;
}

.search-item .search-item-content ul li span:first-child.active::before {
  background: #3751ff;
}

.search-item .search-item-content .batch.active,
.search-item .search-item-content .subject.active {
  font-weight: 700;
  font-size: 14px;
  color: #3751ff;
}

.search-item .search-item-content ul li:last-child {
  margin-bottom: 0;
}

/*Right section
  .right-section {
      min-width: 216px;
      margin-right: 15px;
  }*/

.leaderboard_reward-section {
  min-width: 216px;
  margin-right: 15px;
}

.leader-avatar {
  width: 20px !important;
  height: 20px !important;
  font-size: 9px !important;
  background: #fc8686 !important;
  color: white !important;
  margin-right: 10px;
}

.leaderboard_reward-section {
  min-width: 216px;
}

.leaderboard {
  margin-top: 8px;
}

.leaderboard-content {
  padding: 16px 15px 4px;
  max-height: 470px;
  height: 100%;
}

.leaderboard .leader-body .leader-top {
  padding: 8px 0px;
  /*border-bottom: 1px solid #E0E4F0;*/
}

.leaderboard .leader-body .leader-top:last-child {
  border: none;
}

.leader-para {
  line-height: 20px;
  margin-right: 15px;
}

.leader-avatar {
  width: 20px !important;
  height: 20px !important;
  font-size: 9px !important;
  /* background: #FC8686 !important; */
  color: white !important;
  margin-right: 10px;
  font-family: "PackMan" !important;
}

.test {
  background: #ffffff;
  border: 1px solid #e3e3e3;
  box-shadow: 4px 4px 10px rgba(100, 44, 144, 0.06);
  border-radius: 5px;
  padding: 16px 14px;
  height: 269px;
}

.test-content {
  width: 186px;
  height: 94px;
  background: #dceaff;
  border-radius: 6px;
  border: none;
  padding: 15px;
  margin-top: 15px;
}

.test-content:hover .yellow-btn {
  opacity: 1;
  visibility: visible;
}

.vfc-main-container {
  box-shadow: 4px 4px 10px rgba(100, 44, 144, 0.06) !important;
}

/*Media Query */
@media screen and (max-width: 600px) {
  .select-box .select-content h3 {
    padding: 0px 20px 0;
    padding-top: 20px;
  }

  .teacher-dashboard {
    margin-top: 15px;
  }

  .leaderboard {
    margin-bottom: 15px !important;
  }

  .select-content .right-content .right-subject-box {
    left: 140%;
    margin-top: 1px;
    margin-left: 13px;
  }

  .right-subject-box .right-subject {
    margin-right: 10px;
  }

  .select-box .select-content {
    flex-direction: column;
  }

  .select-content .right-content .right-subject-box {
    max-width: 250px !important;
  }

  .select-box .select-content h3 {
    left: -7px;
  }

  .select-content .right-content .right-subject-box {
    margin-left: -15px;
  }

  .search-item .search-item-content .subject-item {
    max-width: 330px !important;
  }
}

@media screen and (max-width: 475px) {
  .select-content .right-content .right-subject-box {
    max-width: 140px !important;
  }
}

@media screen and (max-width: 475px) {
  .select-content .right-content .right-subject-box {
    max-width: 100px !important;
  }
}

@media screen and (max-width: 400px) {
  .search-item .search-item-content .subject-item {
    max-width: 220px !important;
  }
}

@media screen and (max-width: 1330px) and (min-width: 1150px) {
  /*.select-box, .recommedation {
          width: 535px !important;
      }*/

  .select-content .right-content .right-subject-box {
    max-width: 260px;
  }

  .search-item .search-item-content .subject-item {
    max-width: 180px;
  }
}

@media screen and (max-width: 1150px) {
  .select-content .right-content .right-subject-box {
    max-width: 140px;
  }

  .search-item .search-item-content .subBatchList {
    flex-direction: column !important;
    align-items: flex-start !important;
    width: max-content !important;
  }

  .search-item .search-item-content .subject-item {
    max-width: 240px;
  }

  .search-item .search-item-content ul li span {
    width: auto;
  }

  .search-item {
    padding: 15px 19px;
  }
}

@media screen and (max-width: 1330px) {
  .report-contents {
    grid-template-columns: repeat(1, 1fr);
  }

  .reports .report-link li {
    margin-right: 15px;
  }

  .report-contents .report-card {
    max-width: 100%;
  }

  /*.reports {
          max-height: max-content;
          height: auto;
      }*/
}

@media screen and (max-width: 992px) {
  .teacher-dashboard {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 15px;
  }

  .main-container {
    max-width: 570px;
  }

  .left-section {
    margin-left: 0;
    margin-bottom: 20px;
    padding: 10px;
  }

  .top-box {
    flex-direction: column;
    align-items: center;
    height: auto;
  }

  .leaderboard {
    margin-bottom: 20px !important;
  }

  .middle-section,
  .left-section,
  .leaderboard_reward-section {
    margin-right: 0 !important;
  }

  .leader-sec {
    width: 100%;
  }

  .recommend-sec {
    margin-top: 0 !important;
  }

  /*.select-content .right-content .right-subject-box {
          max-width: 370px;
          min-width: 220px;
      }*/

  /*Right Section */
  .right-section,
  .middle-section,
  .left-section,
  .class-card,
  .test-content {
    max-width: 570px;
    width: 100%;
  }

  .leaderboard_reward-section,
  .right-section {
    margin-right: 0;
  }

  .right-section {
    margin-top: 15px;
  }

  .test {
    position: inherit;
  }

  /*.select-box, .recommedation {
          width: 100% !important;
      }*/

  .search-content {
    max-width: 100%;
    height: 472px;
  }

  .search-item .search-item-content .subject-item {
    max-width: 480px;
  }
}

/*
  @media screen and (max-width: 1080px) {
      .teacher-dashboard {
          flex-direction: column;
          justify-content: center;
          align-items: center;
          padding: 15px;
      }

      .left-section {
          margin-left: 0;
          margin-bottom: 20px;
          padding: 10px;
      }

      .leaderboard {
          margin-bottom: 20px !important;
      }
  .leaderboard_reward-section{
      margin-right: 0;
  }
      .middle-section {
          margin: 0;
      }

      .select-content .right-content .right-subject-box {
          max-width: 370px;
          min-width: 220px;
      }

      .right-section,
      .middle-section,
      .left-section,
      .class-card,
      .test-content {
          max-width: 577px;
          width: 100%;
      }

      .leaderboard_reward-section,
      .right-section {
          margin-right: 0;
      }

      .right-section {
          margin-top: 15px;
      }

      .test {
          position: inherit;
      }

      .select-box, .recommedation {
          width: 100% !important;
      }

      .search-content {
          max-width: 100%;
      }
  }*/

@media screen and (max-width: 1520px) {
  /*.fs-18 {
          font-size: 12px !important;
      }*/

  .extra-classes {
    margin: 0;
    width: 100%;
    /*
          max-width: 577px;
          min-width: 300px;*/
  }

  .extra-classes:nth-child(1) {
    margin-bottom: 15px;
    margin-right: 0;
  }

  .extra-classes:nth-child(2) {
    margin-left: 0;
  }

  .extra-class-content {
    max-width: 577px;
    width: 100%;
  }
}

/*recommedations
  .recommedation{
      width: 801px;
  } */
.recommend-sec {
  width: 100%;
  margin-top: 26px;
  position: absolute;
}

.recommend-content {
  padding: 16px 0px 17px 16px;
  height: 171px;
  margin-bottom: 9px;
  width: 100%;
}

.recommend-section {
  max-width: 570px;
  min-width: 220px;
  padding: 0 15px;
}

.recommend-box {
  width: 170px;
  height: 80px;
}

.recommendation {
  border-bottom: 1px solid #f7f7f7;
  padding-bottom: 10px;
}

.recommendation:last-child {
  border-bottom: none;
  padding-bottom: 0;
}

.recommendation-content {
  margin-top: 11px;
}

.recommendation-content .main-recommendation-box {
  width: 170px;
  margin-right: 16px;
}

.recommendation-content .main-recommendation-box:last-child {
  margin-right: 46px;
}

.recommendation-box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
}

.recommendation-box .progress {
  position: absolute;
  transform: rotate(180deg);
  width: 4px;
  height: 56px;
  background: #f7f7f7;
  right: 9px;
  top: 7%;
  box-shadow: inset 1px 2px 1px rgba(0, 0, 0, 0.25);
}

.progress-bar.transparent {
  background: transparent;
}

.progress-bar.red {
  background: linear-gradient(180deg, #fd7504 0%, #ff3636 100%);
}

.progress-bar.yellow {
  background: yellow;
}

.progress-bar.blue {
  background: blue;
}

.progress-bar.green {
  background: linear-gradient(180deg, #37bc16 0%, #91bc16 100%);
}

.assignment .yellow-btn {
  opacity: 0;
  transition: all 0.3s ease-out;
}

.assignment .recommendation-box:hover .yellow-btn {
  opacity: 1;
}

.extra-class .yellow-btn {
  opacity: 0;
  transition: all 0.3s ease-out;
}

.extra-class .recommendation-box:hover .yellow-btn {
  opacity: 1;
}

.tests .test-btn {
  width: 64px;
  height: 14px;
  border: none;
  background: #ccb6dc;
  cursor: pointer;
  padding: 7px 3px;
  line-height: 0px;
  opacity: 0;
  transition: all 0.3s ease-out;
}

.tests .self-btn {
  width: 64px;
  height: 14px;
  border: none;
  background: #c0d9ff;
  cursor: pointer;
  padding: 7px 3px;
  line-height: 0px;
  opacity: 0;
  transition: all 0.3s ease-out;
}

.tests .recommendation-box:hover .test-btn {
  opacity: 1;
}

/*leaderboard */
.leaderboard_reward-section {
  min-width: 216px;
  margin-right: 15px;
}

.leader-avatar {
  width: 20px !important;
  height: 20px !important;
  font-size: 9px !important;
  /* background: #FC8686 !important; */
  color: white !important;
  margin-right: 10px;
  font-family: "PackMan" !important;
}

.leaderboard .leader-body {
  max-height: 390px;
}

.leaderboard .leader-body.leader-details .leader-top {
  padding: 6px 0px;
  border-bottom: 1px solid #e0e4f0;
}

.leaderboard .leader-body.leader-details .leader-top:first-child {
  padding-top: 0;
}

/*
  .leaderboard .leader-body .leader-top {
      padding: 10px 0px;
      border-bottom: 1px solid #E0E4F0;
  }*/

.leaderboard .leader-body .leader-top:first-child {
  padding-top: 0px;
}

.leaderboard .leader-body .leader-top:last-child {
  border: none;
  padding: 10px 0px 0px;
}

.leader-para {
  line-height: 20px;
  margin-right: 16px;
  width: 50px;
}

.leader-card {
  padding: 27px 15px;
  position: relative;
  max-height: 436px;
}

.practice-image {
  position: absolute;
  top: 36px;
  right: 20px;
  opacity: 0.1;
  height: 47px;
}

input[type="text"]:not(.browser-default)[readonly="readonly"] {
  border-bottom: 1px solid #e3e3e3;
}

.vc-day-content {
  font-size: 12px !important;
}

.vc-container [role="button"],
.vc-container button {
  font-size: 10px !important;
}

.earn-head {
  position: relative;
  width: 100%;
  background: #f3f5fc;
  padding: 20px 0;
  border-radius: 5px 5px 0 0;
}

.modal-button.selected {
  margin-top: 10px;
  background: #f4e7ff;
  border: 1px solid #642c90;
  border-radius: 6px;
  width: 128px;
  height: 50px;
}

.modal-button {
  margin-top: 10px;
  background: #f4e7ff;
  border: 1px solid #642c90;
  cursor: pointer;
  border-radius: 6px;
  width: 128px;
  height: 50px;
}
</style>
